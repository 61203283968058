import styled from 'styled-components';
import { getMargin, getPadding } from '../../styles/marginsPaddings';
import { convertPixelToRem } from '../../styles/sizing';
import { CustomListBox } from '../Home/styles';

export const Main = styled.div`
  background: ${({ theme }) => theme.colors.mainGradient};
  ${getMargin(4)};
  min-height: 100vh;
`;

export const Content = styled.div`
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  padding: 15px;

  div.inputs {
    width: 50%;
  }

  @media screen and (max-width: 968px) {
    div.inputs {
      width: 100%;
    }
  }

  .checkout {
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 4px 4px rgba(61, 75, 127, 0.45);
    border-radius: 5px;
    padding: 45px;
    margin-top: 7%;

    ${CustomListBox} {
      outline: none;
      * {
        outline: none;
      }
    }

    .data-text {
      font-size: ${convertPixelToRem(14)};
      color: ${({ theme }) => theme.colors.colorText};
      margin-top: 25px;
      margin-bottom: 25px;
      flex-wrap: wrap;
    }

    .data-text-email {
      font-size: ${convertPixelToRem(11)};
      color: ${({ theme }) => theme.colors.colorText};
      margin-bottom: 25px;
      flex-wrap: wrap;
      position: relative;
      top: -20px;
    }

    .input-control {
      width: 80%;
      border: solid 1px;
      border-color: #444b62;
      border-radius: 5px;
      height: 39px;
      outline: none;
      background: ${({ theme }) => theme.colors.background};
      padding: 10px;
      display: flex;
      margin-bottom: 25px;
      position: relative;

      .invalid {
        font-size: ${convertPixelToRem(12)};
        color: red;
        flex-wrap: wrap;
        position: absolute;
        margin-top: 29px;
        right: 0;
      }

      input {
        width: 100%;
        outline: none;
        border: none;
        padding: 10px;
      }

      .placeholder {
        position: absolute;
        top: -39px;
        background-color: white;
        padding: 0 10px;
      }

      span {
        font-size: ${convertPixelToRem(12)};
        color: ${({ theme }) => theme.colors.colorText};
        flex-wrap: wrap;
        position: absolute;
        margin-top: 29px;
      }
    }

    @media screen and (max-width: 502px) {
      .input-control {
        width: 100%;
      }
    }

    .checkout-text {
      font-size: ${convertPixelToRem(20)};
      color: ${({ theme }) => theme.colors.colorText};
      font-weight: 600;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  @media screen and (max-width: 502px) {
    .checkout {
      margin-top: 20%;
    }
  }

  .main {
    display: flex;

    .text-review {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    .review-card {
      margin-top: 50px;

      .totalCart {
        display: flex;
        width: 210px;
        justify-content: space-between;
        margin-top: 30px;

        p {
          font-size: ${convertPixelToRem(15)};
          color: ${({ theme }) => theme.colors.colorText};
          flex-wrap: wrap;
        }
        span {
          font-weight: 600;
        }
      }

      .text-review {
        display: flex;
        justify-content: space-between;
      }

      .term-review {
        p {
          font-size: ${convertPixelToRem(14)};
          color: ${({ theme }) => theme.colors.colorText};
          margin-left: 30px;
          flex-wrap: wrap;

          span {
            color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
          }
        }

        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          height: 25px;
          width: 25px;
          background-color: #ccc;
          cursor: pointer;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #cccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .card {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @media screen and (max-width: 743px) {
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
        }

        .request-text-card-title {
          font-weight: 600;
          color: ${({ theme }) => theme.colors.colorText};
        }

        .request-text-card {
          color: ${({ theme }) => theme.colors.colorText};
        }

        align-items: center;

        img {
          padding: 21px;
        }

        .button-card {
          display: block;
          text-align: center;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        button {
          width: 153px;
          ${getPadding(6)};
          margin-bottom: 10px;
          background: none;
          border: solid 1px;
          border-color: ${({ theme }) => theme.colors.colorText};
          border-radius: 5px;
          margin-left: 10px;
          margin-right: 10px;
          &:not(:disabled):hover {
            border-color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
            border: solid 1px;
            background: ${({ theme }) => theme.colors.colorTextButtonCheckout};
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
    @media screen and (max-width: 968px) {
      .review-card {
        width: 100%;
      }
    }
  }

  .catalog {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    @media screen and (max-width: 614px) {
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;

      .checkout-text {
        font-size: ${convertPixelToRem(20)};
        color: ${({ theme }) => theme.colors.colorText};
        font-weight: 600;
        margin-left: 0px;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    button {
      font-size: ${convertPixelToRem(16)};
      max-width: 300px;
      text-transform: uppercase;
      border: none;
      background: ${({ theme }) => theme.colors.colorTextButtonCheckout};
      border-radius: 5px;
      white-space: nowrap;
      width: 100%;
      color: ${({ theme }) => theme.colors.primary};
      height: 37px;
      ${getPadding(6, 20, 6, 20)};
    }
    button:disabled,
    button[disabled],
    button[disabled].button-cart:hover {
      background-color: #cccccc;
      color: #666666;
      cursor: default;
    }
  }
`;
