import styled from 'styled-components';
import { flexAlignCenter, flexBetween } from '../../styles/display';
import { getMargin, getPadding } from '../../styles/marginsPaddings';
import { headerHeight } from '../../styles/sizing';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  ${headerHeight};
  box-shadow: 0px 4px 4px rgba(61, 75, 127, 0.45);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  @media screen and (max-width: 769px) {
    height: 100px;
  }

  .content {
    ${getPadding(25, 100)};
    ${flexAlignCenter}
    ${flexBetween}

    @media screen and (max-width: 769px) {
      ${getPadding(40, 25)};
      display: block;

      .logo {
        justify-content: center;
        position: relative;
        bottom: 20px;
      }

      div {
        flex-wrap: wrap;
      }
    }

    div {
      ${flexBetween}

      p {
        ${getMargin(0, 80, 0, 0)};

        span {
          font-weight: 700;
          ${getMargin(0, 0, 0, 10)};
        }
      }
    }

    button {
      background: none;
      border: none;
    }
  }
`;
