import React from 'react';

import { LoadingProvider } from './Loading';
import { CartProvider } from './Cart';

const AppProvider: React.FC = ({ children }) => {
  return (
    <LoadingProvider>
      <CartProvider>{children}</CartProvider>
    </LoadingProvider>
  );
};

export default AppProvider;
