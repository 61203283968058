import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import CloseIcon from '@material-ui/icons/Close';
import Badge from '@material-ui/core/Badge';
import { useLocation } from 'react-router-dom';
import { Container } from './styles';
import { translations } from './translations';
import lang from '../../services/lang';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { ReactComponent as LogoIconQueOpinas } from '../../assets/queopinas_logo.svg';
import { ReactComponent as CartIcon } from '../../assets/cart.svg';
import { getCoinText, useCart } from '../../hooks/Cart';

const Header: React.FC = () => {
  const pathName = useLocation();
  const { cartAmount, cartItems, setCartItems, amount, currency } = useCart();

  const getLogoPerLanguage = (): ReactElement => {
    if (lang === 'pt') {
      return <LogoIcon />;
    }
    if (lang === 'es') {
      return <LogoIconQueOpinas width="168" height="27" />;
    }

    return <LogoIconQueOpinas width="168" height="27" />;
  };

  return (
    <Container>
      <div className="content">
        <div className="logo">
          <Link to="/">
            <button type="button">{getLogoPerLanguage()}</button>
          </Link>
        </div>
        <div className="cart">
          <p style={{ display: pathName.pathname === '/requests' ? 'none' : 'block' }}>
            {translations[lang].credito}:
            <span>
              {getCoinText(currency)} {amount.toFixed(2)}
            </span>
          </p>
          <Menu>
            <MenuButton style={{ display: pathName.pathname === '/requests' ? 'none' : 'block' }}>
              <Badge badgeContent={cartItems.length} color="primary">
                <CartIcon />
              </Badge>
            </MenuButton>
            <MenuList>
              <MenuItem
                onSelect={() => {
                  ('');
                }}
              >
                <p className="total-items">
                  {translations[lang].cart.totalitems} {cartItems.length}
                </p>
              </MenuItem>
              {cartItems.map((item) => (
                <MenuItem
                  key={`checkout-item-${item.id}`}
                  className="card"
                  onSelect={() => {
                    ('');
                  }}
                >
                  <div>
                    <p>{item.brandName}</p>
                    <p>
                      {getCoinText(currency)} {item.selectedValue}
                    </p>
                  </div>
                  <div className="edit-remove">
                    <CloseIcon
                      key={`checkout-item-${item.id}`}
                      onClick={() => {
                        setCartItems(
                          cartItems.filter((cartItem) => {
                            return cartItem.id !== item.id;
                          }),
                        );
                      }}
                      color="action"
                      style={{ fontSize: 20 }}
                    />
                  </div>
                </MenuItem>
              ))}
              {cartAmount === 0 && (
                <MenuItem
                  onSelect={() => {
                    ('');
                  }}
                >
                  <p className="checkout-cart"> {translations[lang].cart.cartitems}</p>
                </MenuItem>
              )}
              {cartAmount > 0 && (
                <MenuItem
                  onSelect={() => {
                    ('');
                  }}
                >
                  <p className="checkout-text">
                    <span>Total:</span> {getCoinText(currency)}
                    {cartAmount?.toFixed(2)}
                  </p>
                  <Link to="/checkout">
                    <button disabled={!cartAmount} type="button">
                      {translations[lang].cart.buttoncheckout}
                    </button>
                  </Link>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>
      </div>
    </Container>
  );
};

export default Header;
