import styled from 'styled-components';

import { convertPixelToRem, fixedAtTop } from '../../styles/sizing';

export const ModalContainer = styled.div`
  ${fixedAtTop}
  z-index: 999999;
  animation: appear 0.1s ease-in;

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const MessageContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
  width: 100%;

  @media (max-width: 560px) and (max-height: 660px) {
    top: 20%;
  }

  h2 {
    font-size: ${convertPixelToRem(30)};
    color: ${({ theme }) => theme.colors.answerText};
    text-align: center;

    @media (max-width: 560px) {
      font-size: ${convertPixelToRem(26)};
    }
  }
`;

export const Loader = styled.div`
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
