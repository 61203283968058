import styled from 'styled-components';
import { getMargin } from '../../styles/marginsPaddings';
import { convertPixelToRem } from '../../styles/sizing';

export const Main = styled.div`
  background: ${({ theme }) => theme.colors.mainGradient};
  ${getMargin(4)};
  min-height: 100vh;
`;

export const Content = styled.div`
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  padding: 15px;

  div {
    width: 100%;
  }

  .checkout {
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 4px 4px rgba(61, 75, 127, 0.25);
    border-radius: 5px;
    padding: 45px;
    margin-bottom: 40px;
    margin-top: 100px;

    .data-text {
      font-size: ${convertPixelToRem(14)};
      color: ${({ theme }) => theme.colors.colorText};
      margin-top: 25px;
      margin-bottom: 25px;
      flex-wrap: wrap;
    }

    h1 {
      justify-content: center;
      color: #444b62;
      font-size: ${convertPixelToRem(22)};
    }
    }
  }
`;
