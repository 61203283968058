import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from '../pages/NotFound';
import Home from '../pages/Home';
import Checkout from '../pages/Checkout';
import Error from '../pages/Error';
import Requests from '../pages/Requests';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/requests" exact component={Requests} />
      <Route path="/error" exact component={Error} />
      <Route path="/terms" exact component={Terms} />
      <Route path="/privacy" exact component={Privacy} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
