import React, { useState, useEffect, useCallback } from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion';
import { ListboxOption } from '@reach/listbox';
import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import lang from '../../services/lang';
import { getCoinText, getMaxValuesPerCoin, getValuesPerCoin, useCart } from '../../hooks/Cart';
import { useLoading } from '../../hooks/Loading';
import { translations } from './translations';

import Header from '../../components/Header';
import {
  Content,
  Info,
  Main,
  RedemptionContent,
  RedemptionContentWrapper,
  CustomDialogContent,
  CustomDialog,
  CustomListBox,
} from './styles';
import '@reach/menu-button/styles.css';
import '@reach/accordion/styles.css';

import { ReactComponent as MoneyIcon } from '../../assets/money.svg';
import { ReactComponent as GiftCard } from '../../assets/giftCard.svg';
import { ReactComponent as Important } from '../../assets/important.svg';
import { ReactComponent as SearchIcon } from '../../assets/searchIcon.svg';
import { ReactComponent as CloseModal } from '../../assets/close.svg';
import { ReactComponent as ArrowDown } from '../../assets/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/arrowUp.svg';
import { TOKEN_LS_KEY } from '../../utils/appConstants';

type GetCards = () => void;

type tItem = {
  countries: Array<string>;
  utid: string;
  faceValue: number;
  redemptionInstructions: string;
  rewardName: string;
  currencyCode: string;
};

export interface Content {
  id: number;
  brandName: string;
  brandKey: string;
  shortDescription: string;
  description: string;
  disclaimer: string;
  terms: string;
  imageUrls: { '200w-326ppi': string; '300w-326ppi': string };
  items: Array<tItem>;
}

const TERMS_SUBMARINO = 'B139802';
const DISCLAIMER_UBER = 'B562692';
const TERMS_UBER_EATS = 'B553490';

const Home: React.FC = () => {
  const { cartItems, setCartItems, amount, currency, check } = useCart();
  const [searchTerm, setSearchTerm] = useState('');
  const [openCardsControl, setOpenCardsControl] = useState<Record<string, boolean>>({});
  const { turnLoadingOn, loading, turnLoadingOff } = useLoading();
  const [selectedValue, setSelectedValue] = useState<string | number>(0);
  const [cards, setCards] = useState<Array<Content>>([]);
  const [modalContent, setModalContent] = useState<Content>({} as Content);
  const [showDialog, setShowDialog] = React.useState(false);
  const open = (): void => setShowDialog(true);
  const close = (): void => {
    setShowDialog(false);
    setSelectedValue(0);
  };
  const { push } = useHistory();

  const token = sessionStorage.getItem(TOKEN_LS_KEY);

  // eslint-disable-next-line no-shadow
  const addID = (cartItems: Array<Content>): number => {
    if (!cartItems.length) return 1;
    return Math.max(...cartItems.map((cartItem: { id: number }) => cartItem.id)) + 1;
  };

  useEffect(() => {
    const getCards: GetCards = async () => {
      try {
        const {
          data: { catalog },
        } = await api.get(`/retrieve/catalog?currency=${currency}`);
        turnLoadingOn();
        if (currency) {
          setCards(catalog);
          turnLoadingOff();
        }
        turnLoadingOff();
        const showControl = (catalog as any[]).reduce(
          (acc, _next, index) => ({ ...acc, [index]: false }),
          {} as Record<string, boolean>,
        );

        setOpenCardsControl(showControl);
      } catch (erro) {
        turnLoadingOn();
      }
    };
    getCards();
    push('/');
  }, [currency, turnLoadingOn, turnLoadingOff, push]);

  const getPre = (text: string): any => {
    const pre = document.createElement('pre');
    pre.innerHTML = text;
    return (
      <pre>
        {Array.from(pre.querySelectorAll('p')).map((item, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <p key={`paragraph-${index}`}>{item.innerText}</p>;
        })}
      </pre>
    );
  };

  const getPreUl = (text: string): any => {
    const pre = document.createElement('pre');
    pre.innerHTML = text;
    return (
      <pre>
        {Array.from(pre.querySelectorAll('li')).map((item, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <li key={`list-${index}`}>{item.innerText}</li>;
        })}
      </pre>
    );
  };

  const requiredCardKeys = [
    'brandKey',
    'brandName',
    'brandRequirements',
    'createdDate',
    'description',
    'disclaimer',
    'imageUrls',
    'items',
    'lastUpdateDate',
    'shortDescription',
    'status',
    'terms',
  ];

  const filterCards = cards
    // eslint-disable-next-line no-prototype-builtins
    .filter((card) => requiredCardKeys.every((key) => card.hasOwnProperty(key)))
    .filter((value) => {
      if (value.items.length) {
        if (
          searchTerm === '' ||
          value.brandName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        ) {
          return value;
        }
      }
      return false;
    });

  const toggleCardControl = useCallback((cardIndex: number) => {
    setOpenCardsControl((old) => {
      const oldShowValue = old[cardIndex];

      const updatedControl = { ...old, [cardIndex]: !oldShowValue };

      return updatedControl;
    });
  }, []);

  return (
    <>
      {!token && <>{check}</>}
      {token && (
        <>
          <Header />
          <Main>
            <Content>
              <Info>
                <div>
                  <div className="info-wrapper">
                    <div>
                      <MoneyIcon />
                    </div>
                    <p>
                      {translations[lang].pay.p1}
                      <span>
                        {getCoinText(currency)}
                        {getMaxValuesPerCoin(currency)}
                      </span>
                      {translations[lang].pay.p2}
                      <span>{getValuesPerCoin(currency)}</span>
                      <span />
                    </p>
                  </div>
                  <div className="info-wrapper">
                    <div>
                      <GiftCard />
                    </div>
                    <p>
                      {translations[lang].giftcard.p1}
                      <span>{translations[lang].giftcard.span1}</span>
                      {translations[lang].giftcard.p2}
                      <span>{translations[lang].giftcard.span2}</span>
                      {translations[lang].giftcard.p3}
                    </p>
                  </div>
                  <div className="info-wrapper">
                    <div>
                      <Important />
                    </div>
                    <p>
                      {translations[lang].alert.p1}
                      <span>{translations[lang].alert.span1}</span>
                      {translations[lang].alert.p2}
                      <span>{translations[lang].alert.span2}</span>
                    </p>
                  </div>
                </div>
              </Info>
              {!loading && (
                <div className="cards">
                  <div>
                    <div className="input-control">
                      <SearchIcon width="20" height="18" />
                      <input
                        type="search"
                        value={searchTerm}
                        onChange={(event) => {
                          setSearchTerm(event.target.value);
                        }}
                        placeholder={translations[lang].search}
                      />
                    </div>
                    <div>
                      <p className="award-text">{translations[lang].award}</p>
                      <div className="row">
                        {filterCards.map((card, cardIndex) => (
                          <div
                            onClick={() => {
                              open();
                              setModalContent(card);
                            }}
                            key={`${card.brandKey}`}
                            className="card"
                          >
                            <div className="card-header">
                              <img src={card.imageUrls['300w-326ppi']} alt={card.brandName} />
                            </div>
                            <div className="card-body">
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Accordion
                                  collapsible
                                  onChange={() => toggleCardControl(cardIndex)}
                                >
                                  <AccordionItem>
                                    <h3>
                                      <AccordionButton>
                                        {translations[lang].modalcards.values}
                                      </AccordionButton>
                                      <AccordionButton className="arrowButton">
                                        {openCardsControl[cardIndex] ? <ArrowUp /> : <ArrowDown />}
                                      </AccordionButton>
                                    </h3>
                                    {card.items
                                      .sort((a, b) => a.faceValue - b.faceValue)
                                      .map((item) => {
                                        return (
                                          item.faceValue > 0 && (
                                            <AccordionPanel
                                              key={`value-${item.utid}`}
                                              onSelect={() => ''}
                                            >
                                              <div
                                                onClick={() => {
                                                  open();
                                                  setModalContent(card);
                                                }}
                                              >
                                                {currency} {item.faceValue}
                                              </div>
                                            </AccordionPanel>
                                          )
                                        );
                                      })}
                                  </AccordionItem>
                                </Accordion>
                              </div>
                            </div>
                            <p>{card.brandName}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <CustomDialog isOpen={showDialog} onDismiss={close} aria-label="Modal seleção card">
                <CustomDialogContent aria-label="Modal seleção card">
                  {showDialog && modalContent?.brandName && (
                    <>
                      <RedemptionContent>
                        <div className="white">
                          <p className="title">{modalContent.brandName}</p>
                          <RedemptionContentWrapper className="wrapper">
                            <div>
                              <img
                                src={modalContent.imageUrls['200w-326ppi']}
                                alt={modalContent.brandName}
                              />
                            </div>
                            {getPre(`
                        ${modalContent.shortDescription}`)}
                          </RedemptionContentWrapper>
                          {getPre(`
                              ${modalContent.description}
                            `)}
                          {getPre(`
                              ${modalContent.brandKey === TERMS_UBER_EATS || modalContent.terms}
                            `)}
                          {getPreUl(
                            `${modalContent.brandKey === TERMS_SUBMARINO && modalContent.terms}`,
                          )}
                          {getPre(`
                      ${modalContent.brandKey === DISCLAIMER_UBER || modalContent.disclaimer}
                            `)}
                        </div>
                      </RedemptionContent>
                      <div className="value">
                        <p className="title">{translations[lang].modalcards.choosevalue}</p>
                        <div className="button-menu">
                          <div>
                            <div>
                              <CustomListBox
                                portal={false}
                                aria-labelledby="my-label"
                                value={`${selectedValue}`}
                                onChange={setSelectedValue}
                              >
                                <ListboxOption value={String(0)} disabled>
                                  {translations[lang].modalcards.choosevalue}
                                </ListboxOption>
                                {modalContent.items.map(
                                  (selectedItem) =>
                                    selectedItem.faceValue > 0 && (
                                      <ListboxOption
                                        disabled={amount < selectedItem.faceValue}
                                        value={`${selectedItem.faceValue}`}
                                        key={`option-${selectedItem.utid}`}
                                      >
                                        {currency} {selectedItem.faceValue}
                                      </ListboxOption>
                                    ),
                                )}
                              </CustomListBox>
                            </div>
                          </div>
                        </div>
                        <Link to="/checkout">
                          <button
                            disabled={!selectedValue}
                            type="button"
                            onClick={() => {
                              setCartItems([
                                ...cartItems,
                                {
                                  ...modalContent,
                                  id: addID(cartItems),
                                  selectedValue, // VALOR SELECIONADO
                                },
                              ]);
                              close();
                            }}
                          >
                            {translations[lang].modalcards.buttonfinish}
                          </button>
                        </Link>
                        <button
                          disabled={!selectedValue}
                          className="button-cart"
                          type="button"
                          onClick={() => {
                            setCartItems([
                              ...cartItems,
                              {
                                ...modalContent,
                                id: addID(cartItems),
                                selectedValue, // VALOR SELECIONADO
                              },
                            ]);
                            close();
                          }}
                        >
                          {translations[lang].modalcards.buttonaddcart}
                        </button>
                      </div>
                    </>
                  )}
                </CustomDialogContent>
                <div className="close-modal" onClick={close}>
                  <button type="button">
                    <CloseModal />
                  </button>
                </div>
              </CustomDialog>
            </Content>
            {!loading && (
              <div className="terms">
                <p className="data-text">{translations[lang].footer}</p>
              </div>
            )}
          </Main>
        </>
      )}
    </>
  );
};

export default Home;
