import { css, FlattenInterpolation, ThemeProps, DefaultTheme } from 'styled-components';

import { convertPixelToRem, PixelOptions } from './sizing';

type PixelCombinations =
  | [PixelOptions]
  | [PixelOptions, PixelOptions]
  | [PixelOptions, PixelOptions, PixelOptions]
  | [PixelOptions, PixelOptions, PixelOptions, PixelOptions];

export function getPadding(
  ...paddingSizes: PixelCombinations
): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return css`
    padding: ${paddingSizes.map(convertPixelToRem).join(' ')};
  `;
}

export function getMargin(
  ...marginSizes: PixelCombinations
): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return css`
    margin: ${marginSizes.map(convertPixelToRem).join(' ')};
  `;
}
