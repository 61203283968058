const fonts = {
  roboto: 'Roboto, sans-serif',
  poppins: 'Poppins, sans-serif',
};

const defaultColors = {
  primary: '#fff',
  background: '#FFFFFF',
  answerText: '#fff',
  cardButton: '#EAEDEE',
  colorText: '#444B62',
  colorTextButton: '#767676',
  colorTextButtonCheckout: '#4C76F3',
  mainGradient: 'linear-gradient(180deg, #FBFDFF 0%, #DDEDFD 70.31%);',
};

export const theme = {
  colors: defaultColors,

  fonts,
};
