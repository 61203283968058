import { TranslationsCheckoutLanguageTypes } from '../../utils/types';

export const translations: TranslationsCheckoutLanguageTypes = {
  pt: {
    title: 'Checkout',
    yourdata: 'Preencha com seus dados: ',
    inputname: 'Primeiro Nome',
    inputlastname: 'Sobrenome',
    inputmail: 'E-mail',
    textmail: 'O e-mail já foi preenchido automáticamente.',
    choosereview: 'Revise sua escolha',
    totalitems: 'Total de itens:',
    buttonedit: 'Editar',
    buttonremove: 'Remover',
    buttonrefresh: 'Atualizar',
    select: 'Selecionar',
    terms: {
      p1: 'Eu entendo que meu pedido não pode ser devolvido e está sujeito aos ',
      span1: 'Termos de Serviço ',
      p2: 'e ',
      span2: 'Aviso de Privacidade.',
    },
    total: 'Total',
    amount: 'R$',
    buttoncomplete: 'Completar meu pedido',
    buttonbacktocatalog: 'Voltar ao Catálogo',
  },
  es: {
    title: 'Checkout',
    yourdata: 'Complete con sus datos: ',
    inputname: 'Primer Nombre',
    inputlastname: 'Apellido',
    inputmail: 'Correo Electrónico',
    textmail: 'El email ya se ha rellenado automáticamente.',
    choosereview: 'Revise su elección',
    totalitems: 'Artículos totales:',
    buttonedit: 'Modificar',
    buttonremove: 'Quitar',
    buttonrefresh: 'Actualizar',
    select: 'Seleccione',
    terms: {
      p1: 'Entiendo que mi pedido no es reembolsable y está sujeto a los ',
      span1: 'Términos de servicio ',
      p2: 'y el ',
      span2: 'Aviso de privacidad.',
    },
    total: 'Total',
    amount: 'R$',
    buttoncomplete: 'Completar mi pedido',
    buttonbacktocatalog: 'Volver al Catálogo',
  },
  en: {
    title: 'Checkout',
    yourdata: 'Fill in your data: ',
    inputname: 'First Name',
    inputlastname: 'Last Name',
    inputmail: 'Email',
    textmail: 'Email filled automatically.',
    choosereview: 'Review you choice',
    totalitems: 'Total de itens:',
    buttonedit: 'Edit',
    buttonremove: 'Remove',
    buttonrefresh: 'Update',
    select: 'Select',
    terms: {
      p1: 'I understand that my order is non-refundable and is subject to the ',
      span1: 'Terms of Service ',
      p2: 'and ',
      span2: 'Privacy Notice.',
    },
    total: 'Total',
    amount: '$',
    buttoncomplete: 'Complete my order',
    buttonbacktocatalog: 'Return to Catalog',
  },
};
