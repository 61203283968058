import { TranslationsHeaderLanguageTypes } from '../../utils/types';

export const translations: TranslationsHeaderLanguageTypes = {
  pt: {
    credito: 'Crédito',
    coin: 'R$',
    cart: {
      totalitems: 'Total items: ',
      cartitems: 'Carrinho de compras vazio.',
      total: 'Total:',
      buttoncheckout: 'Checkout',
    },
  },
  es: {
    credito: 'Crédito',
    coin: 'R$',
    cart: {
      totalitems: 'Total items: ',
      cartitems: 'Carrito de compras está vacío.',
      total: 'Total:',
      buttoncheckout: 'Checkout',
    },
  },
  en: {
    credito: 'Credit',
    coin: 'R$',
    cart: {
      totalitems: 'Total items: ',
      cartitems: 'Shopping cart is empty.',
      total: 'Total:',
      buttoncheckout: 'Checkout',
    },
  },
};
