import React from 'react';

import { ModalContainer, Loader, MessageContainer } from './styles';

interface ModalProps {
  open: boolean;
  message: string | null;
}

const LoadingModal: React.FC<ModalProps> = ({ open, message }) => {
  if (!open) return null;

  return (
    <ModalContainer>
      {message && (
        <MessageContainer>
          <h2>{message}</h2>
        </MessageContainer>
      )}

      <Loader />
    </ModalContainer>
  );
};

export default LoadingModal;
