import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import { Content, Main } from './styles';
import lang from '../../services/lang';
import { translations } from './translations';

import { ReactComponent as Check } from '../../assets/check.svg';

const Requests: React.FC = () => {
  const { push } = useHistory();

  const goToHome = (): void => {
    push('/');
  };

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Content>
          <div className="checkout">
            <div className="main">
              <Check />
            </div>
            <h1>{translations[lang].title}</h1>
            <p className="request-text">{translations[lang].text}</p>
            <p className="request-text">{translations[lang].awardtext}</p>
            <button type="submit" onClick={goToHome}>
              {translations[lang].button}
            </button>
          </div>
        </Content>
      </Main>
    </>
  );
};

export default Requests;
