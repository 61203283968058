import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const X_TOP = 0;
const Y_TOP = 0;

function ScrollToTop(): null {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(X_TOP, Y_TOP);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
