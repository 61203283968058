import { TranslationsTermsLanguageTypes } from '../../utils/types';

export const translations: TranslationsTermsLanguageTypes = {
  pt: {
    title1: 'TERMOS DE SERVIÇO DO REWARD LINK® ("TERMOS")',
    p1: 'O Reward Link é fornecido pela Tango Card, Inc. ("Tango Card") para disponibilizar uma seleção personalizada de recompensas e incentivos aos Destinatários. O Reward Link só pode ser resgatado pelo Destinatário do Reward Link e apenas para os itens de catálogo do Tango Card especificados.',
    title2: 'Descrição do Reward Link',
    p2: 'O Reward Link fornece acesso a um catálogo de prêmios selecionado pelo cliente do Tango Card (uma "Empresa") do qual você (um "Destinatário") recebeu seu incentivo ou recompensa. A página de destino do catálogo exibirá o saldo disponível para você, que você pode resgatar por qualquer uma das recompensas incentivos ou outros itens mostrados.',
    p3: 'Para usar o Reward Link, basta clicar no URL de resgate e, na página de destino do catálogo de prêmios, prosseguir para selecionar incentivos ou prêmios até o limite do seu saldo e adicioná-los ao carrinho. Ao finalizar a compra, insira o nome e o endereço de e-mail para a entrega do (s) prêmio (s) (seu endereço de e-mail pode ser mostrado por padrão, mas você pode alterá-lo a seu critério).',
    p4: 'Qualquer saldo não utilizado permanecerá disponível para você após a entrega do Prêmio usando o mesmo Link do Prêmio; salve o e-mail de recompensa contendo seu link de recompensa para uso futuro. Observe que seu Reward Link tem uma data de validade para uso. A data de expiração será claramente indicada no e-mail corporativo contendo o link de recompensa. Vários links de recompensa deverão ser resgatados separadamente, pois não podem ser combinados.',
    title3: 'As Coisas Legais Usuais',
    p5: '1. Você declara que tem idade legal para firmar um contrato vinculativo e não é uma pessoa impedida de receber serviços de acordo com as leis dos Estados Unidos. Caso contrário, você não pode usar o Reward Link.',
    p6: '2. Apenas o Destinatário para o qual o Reward Link é emitido pode usá-lo. O Reward Link não pode ser transferido, vendido ou trocado. Se o Tango Card tiver motivos razoáveis para suspeitar que o uso do Reward Link constitui fraude, o Tango Card tem o direito de desativar o Reward Link.',
    p7: '3. O uso do Reward Link pode incluir certas comunicações do Tango Card, como mensagens administrativas. Não enviaremos spam ou venderemos seu nome ou endereço de e-mail a terceiros. Você consente e concorda que o Tango Card pode usar, preservar ou divulgar seu nome e e-mail, se necessário, para: (a) responder ao seu pedido de assistência; (b) cumprir o processo legal; ou (c) proteger o Tango Card, seus usuários e o público.',
    p8: '4. A sua seleção e uso de Cartões-presente do comerciante ou outras opções de recompensa do catálogo do Cartão Tango estarão sujeitos aos Termos e Condições e Aviso de Privacidade do comerciante ou outra parte que fornece o prêmio; esses termos e políticas estão disponíveis para sua revisão por meio do Reward Link. O Tango Card não controla as informações postadas por terceiros ("Conteúdo de terceiros"), incluindo, mas não se limitando a, os termos e condições dos Vales-presente do comerciante e políticas de privacidade e, como tal, não garante a precisão de tal Terceiro Conteúdo da festa. Sob nenhuma circunstância o Tango Card será responsável de qualquer forma por qualquer Conteúdo de Terceiros, incluindo, mas não se limitando a, quaisquer erros ou omissões no Conteúdo de Terceiros.',
    p9: '5. Se uma opção de resgate disponível por meio de seu Reward Link inclui uma doação para uma organização sem fins lucrativos (um "NPO"), o tratamento e o recebimento de sua doação são assunto exclusivamente entre você, a Empresa e o NPO. A Tango Card não se responsabiliza de forma alguma pelo recebimento de recibos para fins fiscais.',
    p10: '6. VOCÊ EXPRESSAMENTE ENTENDE E CONCORDA QUE: (ii) O REWARD LINK É FORNECIDO NUMA BASE "COMO ESTÁ" E "COMO DISPONÍVEL", (iii) TANGO CARD EXPRESSAMENTE RENUNCIA TODAS AS GARANTIAS DE QUALQUER TIPO, SEJA EXPRESSA OU IMPLÍCITA, INCLUINDO, MAS NÃO LIMITADO ÀS GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM E NÃO VIOLAÇÃO. O TANGO CARD NÃO GARANTE QUE: (i) O REWARD LINK ATENDERÁ AS SUAS EXPECTATIVAS OU REQUISITOS, (ii) O REWARD LINK ESTARÁ LIVRE DE ERROS, (iii) A QUALIDADE DE QUAISQUER PRODUTOS OBTIDOS POR VOCÊ ATRAVÉS DO REWARD LINK ATENDERÁ ÀS SUAS EXPECTATIVAS, ( iv) QUE O SITE OU NOSSOS SERVIDORES ESTÃO LIVRES DE VÍRUS OU OUTROS COMPONENTES NOCIVOS; E (v) QUAISQUER ERROS NO SOFTWARE SERÃO CORRIGIDOS. NENHUM CONSELHO OU INFORMAÇÃO, SEJA ORAL OU POR ESCRITO, OBTIDO POR VOCÊ DA TANGO CARD CRIARÁ QUALQUER GARANTIA NÃO EXPRESSAMENTE DECLARADA NESTES TERMOS.',
    p11: '7. Você concorda que, caso o Reward Link não funcione corretamente, nossa única obrigação e sua única solução será a substituição do Reward Link danificado por um que funcione corretamente, se você entrar em contato conosco antes da data de vencimento declarada.',
    p12: '8. Você também concorda e entende que o Tango Card não tem nenhuma responsabilidade pela perda de um Reward Link após o recebimento por você por qualquer motivo, incluindo no caso de seu computador ou dispositivo móvel ser perdido, hackeado ou roubado.',
    p13: '9. Você também concorda e entende que os Comerciantes são os únicos responsáveis por sua capacidade de usar e os termos de uso dos Vales-presente de Comerciantes. Os termos de uso de cada comerciante para seus cartões-presente estão disponíveis no Reward Link. Esses termos e condições regem exclusivamente o uso do (s) Vale (s) Presente (s) do Comerciante. O Tango Card não é e não será de forma alguma responsável por quaisquer ações, políticas ou práticas do Comerciante.',
    p14: '10. O Tango Card pode atribuir seus direitos e responsabilidades a seguir sem aviso prévio a você. Estes Termos constituem o acordo integral entre você e o Tango Card em relação ao seu Reward Link. Estes Termos serão regidos pelas leis do Estado de Washington, independentemente de seu conflito de provisões legais. Você concorda em submeter qualquer reclamação à jurisdição pessoal e exclusiva dos tribunais localizados em King County, Washington. Qualquer reclamação decorrente ou relacionada ao Reward Link será resolvida por arbitragem a ser realizada em King County, Washington, de acordo com as regras da American Arbitration Association. A decisão do árbitro será final, conclusiva e vinculativa para as partes na arbitragem. Qualquer ação movida por você com relação ao Reward Link deve ser movida, se for o caso, dentro de um (1) ano a partir do acúmulo de tal causa de ação.',
    title4:
      'Se o seu Reward Link for entregue a você por correio e / ou incluir uma opção para cartões-presente enviados, estes termos também se aplicam:',
    p15: {
      p1: 'LOS SIGUIENTES TÉRMINOS Y CONDICIONES SE APLICAN A LA TARJETA DE REGALO DE REWARD LINK ELECTRÓNICO: Se puede usar en ',
      p2: 'hasta el saldo SOLO para comprar tarjetas de regalo mostradas y emitidas por comerciantes participantes, incluidas las organizaciones sin fines de lucro (los "Comerciantes participantes"). No se puede canjear en efectivo, excepto según lo exija la ley. Canjeable solo por usted como Destinatario de la recompensa; no puede ser transferido, asignado o vendido por usted. Todos los rescates son definitivos. Los saldos de las Tarjetas de Regalo electrónicas de Reward Link de diferentes programas no se pueden combinar. No recargable. No se puede canjear en sitios web o en tiendas comerciales participantes. Los comerciantes participantes están sujetos a cambios. La Tarjeta de Regalo  de su Reward Link está sujeta a términos adicionales especificados en ',
      p3: 'Proteja la Tarjeta de Regalo de Reward Link. No será reemplazado o repuesto si se pierde, es robado o utilizado sin autorización. Se aplican términos y condiciones separados a las Tarjetas de Regalo compradas. El certificado es distribuido por Tango Card, Inc. Sin embargo, Tango Card, Inc. no está obligado por el titular del certificado y no está afiliado a CARDCO. A CARDCO CIV, Inc. es el emisor de su Tarjeta de Regalo electrónica Reward Link y el único deudor de usted. CARDCO puede delegar sus obligaciones de remitente en un cesionario sin derecho de devolución. En caso de delegación, el cesionario, y no CARDCO, será el único deudor del titular del certificado. El uso o la aceptación del enlace de recompensas de la tarjeta de regalo electrónica constituye los términos de aceptación descritos anteriormente. El monto del saldo de su tarjeta de regalo electrónica Reward Link se puede ver haciendo clic en la URL del enlace de recompensa. Para obtener todas las características del producto y una lista de los comerciantes participantes, visite ',
    },
    title5:
      'Se o seu Reward Link for entregue a você por correio e / ou incluir uma opção para cartões- presente enviados, estes termos também se aplicam: ',
    p16: 'Se você tiver alguma dúvida sobre estes Termos ou qualquer dúvida relacionada ao cliente, entre em contato com: cs@tangocard.com. Telefone: 877-55-tango (877-558-2646) Endereço para correspondência: Tango Card, Inc., 4700 42nd Ave. SW, Suite 430A, Seattle, WA 98116',
  },
  es: {
    title1: 'TÉRMINOS DE SERVICIO DE REWARD LINK® ("TÉRMINOS") ',
    p1: 'El Reward Link es proporcionado por Tango Card, Inc. ("Tango Card") para proporcionar una selección personalizada de recompensas e incentivos a los Destinatarios. Reward Link solo puede ser canjeado por el Destinatario de Reward Link y solo por los artículos especificados del catálogo de Tango Card. ',
    title2: 'Descripción del  Reward Link ',
    p2: 'Reward Link proporciona acceso a un catálogo de premios seleccionado por el cliente de Tango Card (una "Empresa") del cual usted (un "Destinatario") ha recibido su incentivo o recompensa. La página de inicio del catálogo mostrará el saldo disponible para usted, que puede canjear por cualquiera de las recompensas, incentivos u otros artículos que se muestran. ',
    p3: 'Para usar el Reward Link, simplemente haga clic en la URL de canje y, en la página de destino del catálogo de recompensas, proceda a seleccionar incentivos o recompensas hasta el límite de su saldo y agréguelos al carrito. Al final de la compra, ingrese el nombre y la dirección de correo electrónico para la entrega de los premios (su dirección de correo electrónico se puede mostrar de forma predeterminada, pero puede cambiarla a su discreción). ',
    p4: 'Cualquier saldo no utilizado permanecerá disponible para usted después de que el Premio se entregue utilizando el mismo Enlace del Premio; guarde el correo electrónico de recompensa que contiene su enlace de recompensa para su uso futuro. Ten en cuenta que tu RewardLink tiene una fecha de vencimiento para su uso. La fecha de vencimiento se indicará claramente en el correo electrónico corporativo que contiene el enlace de recompensa. Varios enlaces de recompensa deben canjearse por separado, ya que no se pueden combinar. ',
    title3: 'Cosas Legales de Uso ',
    p5: '1. Usted declara que tiene la edad legal para firmar un contrato vinculante y no es una persona a la que se le impida recibir servicios de acuerdo con las leyes de los Estados Unidos. De lo contrario, no puede usar el Reward Link. ',
    p6: '2. Solo el Destinatario para el que se emite el enlace de recompensa puede usarlo. Reward Link no se puede transferir, vender o intercambiar. Si la Tarjeta Tango tiene motivos razonables para sospechar que el uso del enlace de recompensa constituye un fraude, la Tarjeta Tango tiene el derecho de deshabilitar el Reward Link. ',
    p7: '3. El uso de Reward Link puede incluir ciertas comunicaciones de Tango Card, como mensajes administrativos. No enviaremos correo no deseado ni venderemos su nombre o dirección de correo electrónico a terceros. Usted acepta y acepta que Tango Card pueda usar, preservar o divulgar su nombre y dirección de correo electrónico, si es necesario, para: (a) responder a su solicitud de asistencia; (b) cumplir con el proceso legal; o (c) proteger la Tarjeta Tango, a sus usuarios y al público. ',
    p8: '4. Su selección y uso de Tarjetas de Regalo comerciales u otras opciones de recompensa del catálogo de Tango Card estarán sujetas a los Términos y Condiciones y al Aviso de Privacidad del comerciante u otra parte que proporcione el premio; estos términos y políticas están disponibles para su revisión a través de Reward Link. Tango Card no controla la información publicada por terceros ("Contenido de terceros"), incluyendo, entre otros, los términos y condiciones de las Tarjetas de Regalo comerciales y las políticas de privacidad y, como tal, no garantiza la exactitud de dicho Contenido de terceros. En ninguna circunstancia Tango Card será responsable de ninguna manera por cualquier Contenido de Terceros, incluyendo, pero no limitado a, cualquier error u omisión en el Contenido de Terceros. ',
    p9: '5. Si una opción de canje disponible a través de su  Reward  Link incluye una donación a una organización sin fines de lucro (una "NPO"), el tratamiento y la recepción de su donación es un asunto exclusivamente entre usted, la Empresa y la NPO. Tango Card no es responsable de ninguna manera de recibir recibos a efectos fiscales. ',
    p10: '6. USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE: (ii) EL REWARD LINK SE PROPORCIONA "TAL CUAL" Y "SEGÚN DISPONIBILIDAD", (iii) TANGO CARD RENUNCIA EXPRESAMENTE A TODAS LAS GARANTÍAS DE CUALQUIER TIPO, EXPRESAS O IMPLÍCITAS, ICLUYENDO, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE MARKETING, IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y NO VIOLACIÓN. LA TARJETA TANGO NO GARANTIZA QUE: (i) EL REWARD LINK CUMPLIRÁ CON SUS EXPECTATIVAS O REQUISITOS, (ii) EL REWARD LINK ESTARÁ LIBRE DE ERRORES, (iii) LA CALIDAD DE CUALQUIER PRODUCTO OBTENIDO POR USTED A TRAVÉS DE REWARD LINK CUMPLIRÁ CON SUS EXPECTATIVAS, (iv) QUE EL SITIO O NUESTROS SERVIDORES ESTÁN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS; Y (v) CUALQUIER ERROR EN EL SOFTWARE SERÁ CORREGIDO. NINGÚN CONSEJO O INFORMACIÓN, YA SEA ORAL O POR ESCRITO, OBTENIDA POR USTED DE TANGO CARD CREARÁ NINGUNA GARANTÍA QUE NO ESTÉ EXPRESAMENTE ESTABLECIDA EN ESTOS TÉRMINOS. ',
    p11: '7. Usted acepta que, si el  Reward  Link no funciona correctamente, nuestra única obligación y solución será reemplazar el  Reward  Link dañado por uno que funcione correctamente si se comunica con nosotros antes de la fecha de vencimiento declarada. ',
    p12: '8. También acepta y entiende que Tango Card no tiene ninguna responsabilidad por la pérdida de un  Reward  Link al recibirlo por usted por cualquier motivo, incluso en el caso de que su computadora o dispositivo móvil se pierda, piratee o sea robado. ',
    p13: '9. Adicionalmente, acepta y entiende que los Comerciantes son los únicos responsables de su capacidad de uso y los términos de uso de las Tarjetas de Regalo del Comerciante. Los términos de uso de cada comerciante para sus tarjetas de regalo están disponibles en Reward Link. Estos términos y condiciones rigen exclusivamente el uso de los Regalos del Comerciante. El Tango Card no es ni será de ninguna manera responsable de ninguna acción, política o práctica del Comerciante. ',
    p14: '10. Tango Card puede ceder sus derechos y responsabilidades a seguir sin previo aviso. Estos Términos constituyen el acuerdo completo entre usted y Tango Card con respecto a su Reward Link. Estos Términos se regirán por las leyes del Estado de Washington, independientemente de sus disposiciones de conflicto de leyes. Usted acepta someter cualquier reclamo a la jurisdicción personal y exclusiva de los tribunales ubicados en el Condado de King, Washington. Cualquier reclamo que surja de o esté relacionado con el  Reward  Link se resolverá mediante arbitraje que se llevará a cabo en el Condado de King, Washington, de acuerdo con las reglas de la Asociación Americana de Arbitraje. La decisión del árbitro será definitiva, concluyente y vinculante para las partes en el arbitraje. Cualquier acción presentada por usted con respecto al enlace de recompensa debe moverse, si corresponde, dentro de un (1) año a partir de la acumulación de dicha causa de acción. ',
    title4:
      'Si su  Reward  Link se le entrega por correo y/o incluye una opción para Tarjetas de Regalo enviadas, también se aplican estos términos: ',
    p15: {
      p1: 'LOS SIGUIENTES TÉRMINOS Y CONDICIONES SE APLICAN A LA TARJETA DE REGALO DE REWARD LINK ELECTRÓNICO: Se puede usar en ',
      p2: 'hasta el saldo SOLO para comprar tarjetas de regalo mostradas y emitidas por comerciantes participantes, incluidas las organizaciones sin fines de lucro (los "Comerciantes participantes"). No se puede canjear en efectivo, excepto según lo exija la ley. Canjeable solo por usted como Destinatario de la recompensa; no puede ser transferido, asignado o vendido por usted. Todos los rescates son definitivos. Los saldos de las Tarjetas de Regalo electrónicas de Reward Link de diferentes programas no se pueden combinar. No recargable. No se puede canjear en sitios web o en tiendas comerciales participantes. Los comerciantes participantes están sujetos a cambios. La Tarjeta de Regalo  de su Reward Link está sujeta a términos adicionales especificados en ',
      p3: 'Proteja la Tarjeta de Regalo de Reward Link. No será reemplazado o repuesto si se pierde, es robado o utilizado sin autorización. Se aplican términos y condiciones separados a las Tarjetas de Regalo compradas. El certificado es distribuido por Tango Card, Inc. Sin embargo, Tango Card, Inc. no está obligado por el titular del certificado y no está afiliado a CARDCO. A CARDCO CIV, Inc. es el emisor de su Tarjeta de Regalo electrónica Reward Link y el único deudor de usted. CARDCO puede delegar sus obligaciones de remitente en un cesionario sin derecho de devolución. En caso de delegación, el cesionario, y no CARDCO, será el único deudor del titular del certificado. El uso o la aceptación del enlace de recompensas de la tarjeta de regalo electrónica constituye los términos de aceptación descritos anteriormente. El monto del saldo de su tarjeta de regalo electrónica Reward Link se puede ver haciendo clic en la URL del enlace de recompensa. Para obtener todas las características del producto y una lista de los comerciantes participantes, visite ',
    },
    title5:
      'Si su Reward Link se le entrega por correo y/o incluye una opción para Tarjetas de Regalo enviadas, también se aplican estos términos: ',
    p16: 'Si tiene alguna pregunta sobre estos Términos o cualquier pregunta relacionada con el cliente, comuníquese con: cs@tangocard.com. Teléfono: 877-55-tango (877-558-2646) Dirección postal: Tango Card, Inc., 4700 42nd Ave. SW, Suite 430A, Seattle, WA 98116. ',
  },
  en: {
    title1: 'TÉRMINOS DE SERVICIO DE REWARD LINK® ("TÉRMINOS") ',
    p1: 'El Reward Link es proporcionado por Tango Card, Inc. ("Tango Card") para proporcionar una selección personalizada de recompensas e incentivos a los Destinatarios. Reward Link solo puede ser canjeado por el Destinatario de Reward Link y solo por los artículos especificados del catálogo de Tango Card. ',
    title2: 'Descripción del  Reward  Link ',
    p2: 'Reward Link proporciona acceso a un catálogo de premios seleccionado por el cliente de Tango Card (una "Empresa") del cual usted (un "Destinatario") ha recibido su incentivo o recompensa. La página de inicio del catálogo mostrará el saldo disponible para usted, que puede canjear por cualquiera de las recompensas, incentivos u otros artículos que se muestran. ',
    p3: 'Para usar el  Reward  Link, simplemente haga clic en la URL de canje y, en la página de destino del catálogo de recompensas, proceda a seleccionar incentivos o recompensas hasta el límite de su saldo y agréguelos al carrito. Al final de la compra, ingrese el nombre y la dirección de correo electrónico para la entrega de los premios (su dirección de correo electrónico se puede mostrar de forma predeterminada, pero puede cambiarla a su discreción). ',
    p4: 'Cualquier saldo no utilizado permanecerá disponible para usted después de que el Premio se entregue utilizando el mismo Enlace del Premio; guarde el correo electrónico de recompensa que contiene su enlace de recompensa para su uso futuro. Ten en cuenta que tu  RewardLink tiene una fecha de vencimiento para su uso. La fecha de vencimiento se indicará claramente en el correo electrónico corporativo que contiene el enlace de recompensa. Varios enlaces de recompensa deben canjearse por separado, ya que no se pueden combinar. ',
    title3: 'Cosas Legales de Uso ',
    p5: '1. Usted declara que tiene la edad legal para firmar un contrato vinculante y no es una persona a la que se le impida recibir servicios de acuerdo con las leyes de los Estados Unidos. De lo contrario, no puede usar el  Reward  Link. ',
    p6: '2. Solo el Destinatario para el que se emite el enlace de recompensa puede usarlo. Reward Link no se puede transferir, vender o intercambiar. Si la Tarjeta Tango tiene motivos razonables para sospechar que el uso del enlace de recompensa constituye un fraude, la Tarjeta Tango tiene el derecho de deshabilitar el  Reward  Link. ',
    p7: '3. El uso de  Reward  Link puede incluir ciertas comunicaciones de Tango Card, como mensajes administrativos. No enviaremos correo no deseado ni venderemos su nombre o dirección de correo electrónico a terceros. Usted acepta y acepta que Tango Card pueda usar, preservar o divulgar su nombre y dirección de correo electrónico, si es necesario, para: (a) responder a su solicitud de asistencia; (b) cumplir con el proceso legal; o (c) proteger la Tarjeta Tango, a sus usuarios y al público. ',
    p8: '4. Su selección y uso de Tarjetas de Regalo comerciales u otras opciones de recompensa del catálogo de Tango Card estarán sujetas a los Términos y Condiciones y al Aviso de Privacidad del comerciante u otra parte que proporcione el premio; estos términos y políticas están disponibles para su revisión a través de Reward Link. Tango Card no controla la información publicada por terceros ("Contenido de terceros"), incluyendo, entre otros, los términos y condiciones de las Tarjetas de Regalo comerciales y las políticas de privacidad y, como tal, no garantiza la exactitud de dicho Contenido de terceros. En ninguna circunstancia Tango Card será responsable de ninguna manera por cualquier Contenido de Terceros, incluyendo, pero no limitado a, cualquier error u omisión en el Contenido de Terceros. ',
    p9: '5. Si una opción de canje disponible a través de su Reward Link incluye una donación a una organización sin fines de lucro (una "NPO"), el tratamiento y la recepción de su donación es un asunto exclusivamente entre usted, la Empresa y la NPO. Tango Card no es responsable de ninguna manera de recibir recibos a efectos fiscales. ',
    p10: '6. USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE: (ii) EL REWARD LINK SE PROPORCIONA "TAL CUAL" Y "SEGÚN DISPONIBILIDAD", (iii) TANGO CARD RENUNCIA EXPRESAMENTE A TODAS LAS GARANTÍAS DE CUALQUIER TIPO, EXPRESAS O IMPLÍCITAS, ICLUYENDO, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE MARKETING, IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y NO VIOLACIÓN. LA TARJETA TANGO NO GARANTIZA QUE: (i) EL REWARD LINK CUMPLIRÁ CON SUS EXPECTATIVAS O REQUISITOS, (ii) EL REWARD LINK ESTARÁ LIBRE DE ERRORES, (iii) LA CALIDAD DE CUALQUIER PRODUCTO OBTENIDO POR USTED A TRAVÉS DE REWARD LINK CUMPLIRÁ CON SUS EXPECTATIVAS, (iv) QUE EL SITIO O NUESTROS SERVIDORES ESTÁN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS; Y (v) CUALQUIER ERROR EN EL SOFTWARE SERÁ CORREGIDO. NINGÚN CONSEJO O INFORMACIÓN, YA SEA ORAL O POR ESCRITO, OBTENIDA POR USTED DE TANGO CARD CREARÁ NINGUNA GARANTÍA QUE NO ESTÉ EXPRESAMENTE ESTABLECIDA EN ESTOS TÉRMINOS. ',
    p11: '7. Usted acepta que, si el  Reward  Link no funciona correctamente, nuestra única obligación y solución será reemplazar el  Reward  Link dañado por uno que funcione correctamente si se comunica con nosotros antes de la fecha de vencimiento declarada. ',
    p12: '8. También acepta y entiende que Tango Card no tiene ninguna responsabilidad por la pérdida de un  Reward  Link al recibirlo por usted por cualquier motivo, incluso en el caso de que su computadora o dispositivo móvil se pierda, piratee o sea robado. ',
    p13: '9. Adicionalmente, acepta y entiende que los Comerciantes son los únicos responsables de su capacidad de uso y los términos de uso de las Tarjetas de Regalo del Comerciante. Los términos de uso de cada comerciante para sus tarjetas de regalo están disponibles en Reward Link. Estos términos y condiciones rigen exclusivamente el uso de los Regalos del Comerciante. El Tango Card no es ni será de ninguna manera responsable de ninguna acción, política o práctica del Comerciante. ',
    p14: '10. Tango Card puede ceder sus derechos y responsabilidades a seguir sin previo aviso. Estos Términos constituyen el acuerdo completo entre usted y Tango Card con respecto a su Reward Link. Estos Términos se regirán por las leyes del Estado de Washington, independientemente de sus disposiciones de conflicto de leyes. Usted acepta someter cualquier reclamo a la jurisdicción personal y exclusiva de los tribunales ubicados en el Condado de King, Washington. Cualquier reclamo que surja de o esté relacionado con el  Reward  Link se resolverá mediante arbitraje que se llevará a cabo en el Condado de King, Washington, de acuerdo con las reglas de la Asociación Americana de Arbitraje. La decisión del árbitro será definitiva, concluyente y vinculante para las partes en el arbitraje. Cualquier acción presentada por usted con respecto al enlace de recompensa debe moverse, si corresponde, dentro de un (1) año a partir de la acumulación de dicha causa de acción. ',
    title4:
      'Si su  Reward  Link se le entrega por correo y/o incluye una opción para Tarjetas de Regalo enviadas, también se aplican estos términos: ',
    p15: {
      p1: 'LOS SIGUIENTES TÉRMINOS Y CONDICIONES SE APLICAN A LA TARJETA DE REGALO DE REWARD LINK ELECTRÓNICO: Se puede usar en ',
      p2: 'hasta el saldo SOLO para comprar tarjetas de regalo mostradas y emitidas por comerciantes participantes, incluidas las organizaciones sin fines de lucro (los "Comerciantes participantes"). No se puede canjear en efectivo, excepto según lo exija la ley. Canjeable solo por usted como Destinatario de la recompensa; no puede ser transferido, asignado o vendido por usted. Todos los rescates son definitivos. Los saldos de las Tarjetas de Regalo electrónicas de Reward Link de diferentes programas no se pueden combinar. No recargable. No se puede canjear en sitios web o en tiendas comerciales participantes. Los comerciantes participantes están sujetos a cambios. La Tarjeta de Regalo  de su Reward Link está sujeta a términos adicionales especificados en ',
      p3: 'Proteja la Tarjeta de Regalo de Reward Link. No será reemplazado o repuesto si se pierde, es robado o utilizado sin autorización. Se aplican términos y condiciones separados a las Tarjetas de Regalo compradas. El certificado es distribuido por Tango Card, Inc. Sin embargo, Tango Card, Inc. no está obligado por el titular del certificado y no está afiliado a CARDCO. A CARDCO CIV, Inc. es el emisor de su Tarjeta de Regalo electrónica Reward Link y el único deudor de usted. CARDCO puede delegar sus obligaciones de remitente en un cesionario sin derecho de devolución. En caso de delegación, el cesionario, y no CARDCO, será el único deudor del titular del certificado. El uso o la aceptación del enlace de recompensas de la tarjeta de regalo electrónica constituye los términos de aceptación descritos anteriormente. El monto del saldo de su tarjeta de regalo electrónica Reward Link se puede ver haciendo clic en la URL del enlace de recompensa. Para obtener todas las características del producto y una lista de los comerciantes participantes, visite ',
    },
    title5:
      'Si su Reward Link se le entrega por correo y/o incluye una opción para Tarjetas de Regalo enviadas, también se aplican estos términos: ',
    p16: 'Si tiene alguna pregunta sobre estos Términos o cualquier pregunta relacionada con el cliente, comuníquese con: cs@tangocard.com. Teléfono: 877-55-tango (877-558-2646) Dirección postal: Tango Card, Inc., 4700 42nd Ave. SW, Suite 430A, Seattle, WA 98116. ',
  },
};
