import React, { createContext, useCallback, useContext, useState } from 'react';

import LoadingModal from '../components/LoadingModal';

interface UseLoading {
  loading: boolean;
  toggleLoading: () => void;
  turnLoadingOn: (message?: string | null) => void;
  turnLoadingOff: () => void;
}

const LoadingContext = createContext<UseLoading>({} as UseLoading);

export const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setIsOpen] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const toggleLoading = useCallback(() => setIsOpen((pastVisibility) => !pastVisibility), []);

  const turnLoadingOn = useCallback((message: string | null = null) => {
    setLoadingMessage(message);
    setIsOpen(true);
  }, []);

  const turnLoadingOff = useCallback(() => {
    setIsOpen(false);
    setLoadingMessage(null);
  }, []);

  return (
    <LoadingContext.Provider value={{ loading, turnLoadingOn, toggleLoading, turnLoadingOff }}>
      <>
        <LoadingModal open={loading} message={loadingMessage} />

        {children}
      </>
    </LoadingContext.Provider>
  );
};

export function useLoading(): UseLoading {
  const context = useContext(LoadingContext);

  if (!context.turnLoadingOff) throw new Error('');

  return context;
}
