import { TranslationsLanguageTypes } from '../../utils/types';

export const translations: TranslationsLanguageTypes = {
  pt: {
    pay: {
      p1: 'A cada resgate você terá ',
      span1: ' 100 ',
      p2: ' para gastar nas lojas disponíveis. Existem diversas opções de lojas e diferentes valores de resgate em cada uma delas que variam de ',
      span2: '20 a ',
      span3: ' 300 reais.',
    },
    giftcard: {
      p1: 'O VoceOpina card é único, ',
      span1: 'intrasferível e não possui data de validade, ',
      p2: 'ou seja, você poderá acumular e utilizar esse valor quando e como quiser. ',
      span2: 'Mas fique atento, ',
      p3: 'assim que você finalizar sua compra, observe que haverá um prazo para utilizar o valor escolhido nas lojas selecionadas.',
    },
    alert: {
      p1: 'Caso você não utilize todo o valor disponível no seu ',
      span1: 'VoceOpina Card, ',
      p2: 'não se preocupe! Os seus próximos resgates de Vale Presente serão sempre ',
      span2: 'adicionados ao seu crédito.',
    },
    search: 'Procurar Cartão',
    award: 'Prêmio',
    footer:
      'As marcas representadas não são patrocinadores das recompensas ou de outra forma afiliadas a esta empresa. Os logotipos e outras marcas de identificação anexadas são marcas registradas e de propriedade de cada empresa representada e/ou suas afiliadas. Visite o site de cada empresa para termos e condições adicionais.',
    modalcards: {
      values: 'Valores disponíveis',
      choosevalue: 'Escolha o valor',
      select: 'Selecione um valor',
      buttonfinish: 'Escolher e Finalizar',
      buttonaddcart: 'Adicionar ao Carrinho',
    },
  },
  es: {
    pay: {
      p1: 'Con cada canje tendrá ',
      span1: ' ',
      p2: ' para gastar en las tiendas disponibles. Hay varias tiendas y diferentes valores de redención en cada una de ellas, que van desde ',
      span2: '',
      span3: '',
    },
    giftcard: {
      p1: 'La tarjeta QueOpinas es única, ',
      span1: 'intransferible y no tiene fecha de vencimiento, ',
      p2: 'es decir, puedes acumular y utilizar este valor cuando y como quieras. ',
      span2: 'Pero esté atento, ',
      p3: 'tan pronto como complete su compra, tenga en cuenta que habrá una fecha límite para utilizar el valor elegido en las tiendas seleccionadas.',
    },
    alert: {
      p1: 'En caso de que no utilice todo el importe disponible en su Tarjeta ',
      span1: 'QueOpinas, ',
      p2: '¡no se preocupe! Sus próximos canjes de Tarjetas de Regalo siempre se ',
      span2: 'agregarán a su crédito.',
    },
    search: 'Buscar',
    award: 'Premio',
    footer:
      'Las marcas representadas no son patrocinadores de recompensas ni están afiliadas a esta empresa. Los logotipos y otras marcas de identificación adjuntas son marcas registradas y de propiedad de cada empresa representada y/o sus afiliadas. Visite el sitio web de cada empresa para obtener términos y condiciones adicionales.',
    modalcards: {
      values: 'Valores disponibles',
      choosevalue: 'Elige el valor',
      select: 'Seleccione un valor',
      buttonfinish: 'Elegir y Finalizar',
      buttonaddcart: 'Añandir al Carrito',
    },
  },
  en: {
    pay: {
      p1: 'You will have ',
      span1: '$Arg 50 ',
      p2: 'to spend with each redemption in available stores. There are several stores options and different redemption values for each, ranging from ',
      span2: ' 20 ',
      span3: ' 2.600,00.',
    },
    giftcard: {
      p1: 'The VoceOpina card is unique, ',
      span1: 'non-transferable, and has no expiration date; ',
      p2: 'that is, you can accumulate and use this value whenever and however you want. ',
      span2: 'But bear in mind that once you have completed your purchase, ',
      p3: 'there will be a deadline to use the specified amount at the selected stores.',
    },
    alert: {
      p1: 'If you dont use the full amount available on your ',
      span1: 'VoceOpina Card, ',
      p2: 'dont worry! Your next Gift Card redemptions will always be ',
      span2: 'added to your credit balance.',
    },
    search: 'Search Card',
    award: 'Reward',
    footer:
      'The brands shown are not rewards sponsors or otherwise affiliated with this company. The logos and other trademarks attached are registered and proprietary marks of each represented company and/or its affiliates. Please visit each company website for additional terms and conditions.',
    modalcards: {
      values: 'Available values',
      choosevalue: 'Choose an amount',
      select: 'Select an amount',
      buttonfinish: 'Choose and Finish',
      buttonaddcart: 'Add to Cart',
    },
  },
};
