import React from 'react';

import ErrorIcon from '@material-ui/icons/Error';

import { Container } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <div>
        <ErrorIcon />

        <h2>Página não encontrada</h2>

        <p>Parece que não há nada aqui.</p>
      </div>
    </Container>
  );
};

export default NotFound;
