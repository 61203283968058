import styled from 'styled-components';
import { Dialog, DialogContent } from '@reach/dialog';
import { Listbox } from '@reach/listbox';
import { flexAlignCenter } from '../../styles/display';
import { getMargin, getPadding } from '../../styles/marginsPaddings';
import { convertPixelToRem } from '../../styles/sizing';

export const Main = styled.div`
  background: ${({ theme }) => theme.colors.mainGradient};
  ${getMargin(4)};
  min-height: 100vh;

  .terms {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 40px;

    .data-text {
      font-size: ${convertPixelToRem(14)};
      color: ${({ theme }) => theme.colors.colorText};
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 500px) {
    .terms {
      max-width: 320px;
    }
  }
`;

export const Info = styled.div`
  margin-top: 70px;

  div {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    ${getMargin(20, 0, 30, 0)};
    ${flexAlignCenter}
    justify-content: center;

    @media screen and (max-width: 769px) {
      flex-wrap: wrap;
      justify-content: center;

      p {
        text-align: center;
        margin-right: 0;
        margin-left: 0;
      }
    }

    p {
      font-size: ${convertPixelToRem(13)};
      max-width: 380px;
      line-height: 20px;
      font-weight: 400;
      font-style: normal;
      margin-right: 50px;
      margin-left: 20px;
      color: ${({ theme }) => theme.colors.colorText};

      @media screen and (max-width: 769px) {
        margin-right: 0;
        text-align: center;
        margin-right: 20px;
        margin-left: 20px;
      }

      span {
        font-weight: 700;
      }
    }
  }
`;

export const Content = styled.div`
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  padding: 15px;

  div {
    width: 100%;
  }

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cards {
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 4px 4px rgba(61, 75, 127, 0.45);
    border-radius: 5px;
    padding: 45px;
    margin-bottom: 40px;

    @media screen and (max-width: 769px) {
      padding: 15px;
    }

    @media screen and (max-width: 614px) {
      margin-top: 50px;
      padding: 10px;
    }

    div {
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
    }

    .input-control {
      width: 100%;
      border: none;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      height: 39px;
      outline: none;
      background: ${({ theme }) => theme.colors.background};
      padding: 10px;
      display: flex;

      input {
        width: 100%;
        outline: none;
        border: none;
        padding: 10px;
      }

      input::placeholder {
        color: ${({ theme }) => theme.colors.colorTextButton};
      }
    }

    .award-text {
      font-size: ${convertPixelToRem(20)};
      color: ${({ theme }) => theme.colors.colorText};
      font-weight: 600;
      margin-top: 25px;
      margin-left: 25px;
      flex-wrap: wrap;
    }

    .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      grid-gap: 7px;
      margin-top: 35px;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
      flex: 1 1 100%;

      #panel {
        width: 200px;
        height: 200px;
        background: red;
      }

      .card {
        width: 300px;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 10px;
        min-width: 260px;
        transition: 0.3s;
        cursor: pointer;
        margin-bottom: 10px;

        @media screen and (max-width: 1366px) {
          width: 290px;
        }

        @media screen and (max-width: 1024px) {
          width: 305px;
        }

        p {
          font-size: ${convertPixelToRem(11)};
          color: ${({ theme }) => theme.colors.colorTextButton};
          margin-top: 20px;
          margin-left: 10px;
          margin-bottom: 20px;
        }

        .card-header {
          width: 100%;
          height: 170px;
          border-radius: 5px;

          img {
            width: 100%;
            height: 170px;
          }
        }

        .card-body {
          display: flex;
          margin-top: 20px;

          button {
            font-size: ${convertPixelToRem(11)};
            text-transform: uppercase;
            border: none;
            background: #eaedee;
            border-radius: 5px;
            white-space: nowrap;
            color: ${({ theme }) => theme.colors.colorText};
            ${getPadding(8, 15, 8, 15)};
          }
        }
      }

      .card:hover {
        transform: scale(1.03);
        box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export const CustomDialog = styled(Dialog)`
  padding: 5px;
  max-width: fit-content;
  display: flex;

  button {
    border: none;
  }

  .close-modal {
    position: relative;
    bottom: 10px;
    right: 5px;
  }
`;

export const CustomListBox = styled(Listbox)`
  outline: none;
`;

export const CustomDialogContent = styled(DialogContent)`
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &[data-reach-dialog-content] {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
  }

  .value {
    margin: auto;
    text-align: center;
    max-width: 280px;

    button:disabled,
    button[disabled] {
      background-color: #cccccc;
      color: #666666;
      cursor: default;
    }

    @media screen and (max-width: 1605px) {
      margin-top: 30px;
    }

    ${CustomListBox} {
      outline: none;
      * {
        outline: none;
      }
    }

    .button-cart {
      font-size: ${convertPixelToRem(16)};
      text-transform: uppercase;
      border-color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
      border: solid 2px;
      background: none;
      border-radius: 5px;
      white-space: nowrap;
      width: 100%;
      color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
      height: 37px;
      min-width: 270px;
      transition: all 0.3s cubic-bezier(0.375, 0.885, 0.115, 1.275);
      &:not(:disabled):hover {
        border-color: ${({ theme }) => theme.colors.colorTextButtonCheckout};
        border: solid 2px;
        background: ${({ theme }) => theme.colors.colorTextButtonCheckout};
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    div {
      align-items: center;
      justify-content: center;
    }

    .button-menu {
      margin-top: 25px;
      margin-bottom: 25px;
      border: none;

      [data-reach-listbox-button] {
        background: white;
        border: none;
        ${getPadding(5, 10, 5, 10)};
        cursor: pointer;
        min-width: 270px;
      }

      [data-reach-listbox-popover] {
        width: 280px;
      }

      [data-reach-listbox-popover]:focus {
        outline: none;
      }

      button {
        font-size: ${convertPixelToRem(16)};
        text-transform: uppercase;
        border: none;
        background: white;
        border-radius: 5px;
        white-space: nowrap;
        width: 100%;
        color: ${({ theme }) => theme.colors.colorText};
        height: 37px;
        justify-content: space-between;
        display: flex;
        ${getPadding(7, 20, 7, 15)};
      }
    }

    button {
      font-size: ${convertPixelToRem(16)};
      text-transform: uppercase;
      border: none;
      background: ${({ theme }) => theme.colors.colorTextButtonCheckout};
      border-radius: 5px;
      white-space: nowrap;
      width: 100%;
      color: ${({ theme }) => theme.colors.primary};
      height: 37px;
      margin-bottom: 10px;
      min-width: 270px;
    }

    .title {
      font-size: ${convertPixelToRem(20)};
      color: ${({ theme }) => theme.colors.colorText};
      font-weight: 600;
      flex-wrap: wrap;
      margin: 0;
    }
  }
`;

export const RedemptionContent = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  max-width: 765px;

  @media screen and (max-width: 1366px) {
    max-width: 860px;
  }

  @media screen and (max-width: 992px) {
    max-width: 100%;
    padding: 1px;
    text-align: center;
  }

  .title {
    font-size: ${convertPixelToRem(15)};
    color: ${({ theme }) => theme.colors.colorText};
    font-weight: 600;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-top: 20px;
  }

  pre {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;

    > li {
      max-width: 100%;
      white-space: break-spaces;
      font-size: 13px;
    }

    li:nth-child(3) {
      margin-bottom: 15px;
    }

    li:nth-child(4) {
      display: none;
    }

    li:nth-child(5) {
      display: none;
    }

    li:nth-child(6) {
      display: none;
    }

    > p {
      max-width: 100%;
      white-space: break-spaces;
      margin-bottom: 15px;
      font-size: 13px;
      //remove itens duplicated
      &:nth-of-type(even) {
        display: none;
      }
    }

    p:nth-child(3) {
      display: none;
    }

    p:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const RedemptionContentWrapper = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
`;

export const Currency = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  h1 {
    margin: 10px;
    font-size: ${convertPixelToRem(20)};
    color: ${({ theme }) => theme.colors.colorText};
    font-weight: 600;
    margin-top: 25px;
    margin-left: 25px;
    flex-wrap: wrap;
    text-align: center;
  }

  div {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    top: -40%;
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 4px 4px rgba(61, 75, 127, 0.45);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  @media screen and (max-width: 769px) {
    height: 100px;
  }

  .content {
    ${getPadding(25, 100)};
    ${flexAlignCenter}

    @media screen and (max-width: 769px) {
      ${getPadding(40, 25)};
      display: block;

      .logo {
        justify-content: center;
        position: relative;
        bottom: 20px;
      }

      div {
        flex-wrap: wrap;
      }
    }

    div {
      p {
        ${getMargin(0, 80, 0, 0)};

        span {
          font-weight: 700;
          ${getMargin(0, 0, 0, 10)};
        }
      }
    }

    button {
      background: none;
      border: none;
    }
  }
`;
