import styled from 'styled-components';
import { flexAlignCenter, flexMiddle } from '../../styles/display';
import { getMargin, getPadding } from '../../styles/marginsPaddings';
import { convertPixelToRem, getBoxWidthAndHeight } from '../../styles/sizing';

export const Container = styled.div`
  ${getPadding(0, 20)};
  height: 100vh;

  ${flexMiddle}

  > div {
    ${flexAlignCenter}
    flex-direction: column;

    svg {
      ${getBoxWidthAndHeight(70)};
      color: ${({ theme }) => theme.colors.primary};
    }

    h2 {
      ${getMargin(15, 0)}
      font-size: ${convertPixelToRem(34)};
      line-height: ${convertPixelToRem(40)};
      text-align: center;
    }
  }
`;
