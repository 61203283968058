import { useState } from 'react';
import { useLocation } from 'react-router';

export type QueryParams = Record<string, string[]>;

export function useQueryParams(): QueryParams {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const searchAsArray = Array.from(searchParams.entries());

  const [queryParams] = useState(() => {
    const params = searchAsArray.reduce((accParams, [key, value]) => {
      const existingKeyValue = accParams[key] || [];

      const updatedValue = [...existingKeyValue, value];

      const updatedAccumulator = { ...accParams, [key]: updatedValue };

      return updatedAccumulator;
    }, {} as QueryParams);

    return params;
  });

  return queryParams;
}
