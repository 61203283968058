import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect } from 'react';
import { ListboxOption } from '@reach/listbox';
import { useLoading } from '../../hooks/Loading';
import Header from '../../components/Header';
import { Content, Main } from './styles';
import { useCart, tCartItem, getCoinText } from '../../hooks/Cart';
import { translations } from './translations';
import lang from '../../services/lang';
import api from '../../services/api';
import { CustomListBox } from '../Home/styles';

type tItem = {
  countries: Array<string>;
  utid: string;
  faceValue: number;
  redemptionInstructions: string;
  rewardName: string;
  currencyCode: string;
  selectedValue: number;
};

export interface Content {
  id: number;
  brandName: string;
  brandKey: string;
  shortDescription: string;
  description: string;
  disclaimer: string;
  terms: string;
  imageUrls: any;
  items: Array<tItem>;
}

const wait = (timeout: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

const Checkout: React.FC = () => {
  const { cartItems, setCartItems, user, amount, cartAmount, setStock, currency } = useCart();
  const { loading, turnLoadingOn, turnLoadingOff } = useLoading();
  const [userAgreement, setUserAgreement] = useState(false);
  const [nameValidate, setNameValidate] = useState(false);
  const [lastNameValidade, setLastNameValidate] = useState(false);
  const [userName, setUserName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const { push } = useHistory();
  const [selectedValue, setSelectedValue] = useState<string | number>(0);

  const emailSessionStorage = sessionStorage.getItem('user_email');
  const TWO_AND_A_HALF_SECONDS = 2500;

  const changeEdit = (id?: number, showEdit?: boolean): void => {
    setCartItems(
      cartItems?.map((item) => {
        const value = item;
        if (value.id === id && showEdit) {
          value.showEdit = true;
        } else {
          value.showEdit = false;
        }
        return value;
      }),
    );
  };

  const getCartSum = (selectedCards: tCartItem[]): number =>
    selectedCards.reduce((acumul, item) => acumul + Number(item.selectedValue), 0);

  const handleComplete = async (): Promise<void> => {
    const regex = /[a-zA-Z]{3,}/;

    if (!regex.test(userName)) {
      setNameValidate(true);
    }
    if (!regex.test(userLastName)) {
      setLastNameValidate(true);
    }

    if (
      regex.test(userName) &&
      regex.test(userLastName) &&
      getCartSum(cartItems) <= user?.panelist.amount
    ) {
      // We need to proccess our items in order and respecting the API's 2sec backoff rule
      turnLoadingOn();
      const outOfStockList: any = [];
      for (const cartItem of cartItems) { // eslint-disable-line
        const utid = cartItem.items.find(
          (selectedItem) => Number(selectedItem.faceValue) === Number(cartItem.selectedValue),
        )?.utid;
        const order = {
          utid,
          panelistId: user?.panelist.panelistId,
          amount: cartItem.selectedValue,
          email: user.panelist.email,
          firstName: userName,
          lastName: userLastName,
        };

        try {
          const { data } = await api.post('/place/order', { order }); // eslint-disable-line
        } catch (error: any) {
          if (error.response.status === 400) {
            outOfStockList.push(utid);
          }
          console.error(error);
        }

        await wait(TWO_AND_A_HALF_SECONDS); // eslint-disable-line
      }
      if (outOfStockList.length) {
        let getUtidCartItems: any = [];
        const productToString = outOfStockList.toString();
        // eslint-disable-next-line no-restricted-syntax
        for (const cartItem of cartItems) {
          getUtidCartItems = cartItem.items.find(
            (selectedItem) => Number(selectedItem.faceValue) === Number(cartItem.selectedValue),
          )?.utid;
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const utidOutStock of productToString) {
          // eslint-disable-next-line no-restricted-syntax
          for (const utidCartItems of getUtidCartItems) {
            if (utidOutStock === utidCartItems) {
              setStock(cartItems);
            }
          }
        }
        setCartItems([]);
        turnLoadingOff();
        push('/Error');
        return;
      }
      setCartItems([]);
      turnLoadingOff();
      push('/requests');
    }
  };

  useEffect(() => {
    if (user?.panelist) return;
    push('/');
  });

  return (
    <>
      <Header />
      <Main>
        <Content>
          {!loading && (
            <div className="checkout">
              <div className="main">
                <div className="inputs">
                  <p className="checkout-text">{translations[lang].title}</p>
                  <p className="data-text">{translations[lang].yourdata}</p>
                  <div className="input-control">
                    <input
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      type="text"
                    />
                    <span className="placeholder">{translations[lang].inputname}*</span>
                    {nameValidate && <span className="invalid">Campo inválido</span>}
                  </div>
                  <div className="input-control">
                    <input
                      value={userLastName}
                      onChange={(e) => setUserLastName(e.target.value)}
                      type="text"
                    />
                    <span className="placeholder">{translations[lang].inputlastname}*</span>
                    {lastNameValidade && <span className="invalid">Campo inválido</span>}
                  </div>
                  <div className="input-control">
                    <input
                      type="text"
                      value={`${emailSessionStorage}`}
                      placeholder="E-mail"
                      disabled
                    />
                    <span className="placeholder">{translations[lang].inputmail}</span>
                  </div>
                  <p className="data-text-email">{translations[lang].textmail}</p>
                </div>
                {cartItems.length === 0 && (
                  <div className="text-review">
                    <p className="data-text">{translations[lang].choosereview}</p>
                    <p className="data-text">
                      {translations[lang].totalitems} {cartItems.length}
                    </p>
                  </div>
                )}
                {cartItems.length > 0 && (
                  <div className="review-card">
                    <div className="text-review">
                      <p className="data-text">{translations[lang].choosereview}:</p>
                      <p className="data-text">
                        {translations[lang].totalitems} {cartItems.length}
                      </p>
                    </div>
                    {cartItems.map((item) => (
                      <div key={`checkout-item-${item.id}`} className="card">
                        <img src={item.imageUrls['200w-326ppi']} alt="" />
                        <div>
                          <p className="request-text-card-title">{item.brandName}</p>
                          {!item.showEdit && (
                            <p className="request-text-card">
                              {getCoinText(currency)} {item.selectedValue}
                            </p>
                          )}
                          {item.showEdit && (
                            <CustomListBox
                              portal={false}
                              value={`${selectedValue}`}
                              onChange={setSelectedValue}
                            >
                              <ListboxOption value={String(0)} disabled>
                                {translations[lang].select}
                              </ListboxOption>
                              {cartItems
                                .filter((cartItem) => item.id === cartItem.id)[0]
                                .items.map((value) => (
                                  <ListboxOption
                                    disabled={
                                      value.faceValue >
                                      amount +
                                      Number(
                                        cartItems.filter((cartItem) => item.id === cartItem.id)[0]
                                          .selectedValue,
                                      )
                                    }
                                    value={`${value.faceValue}`}
                                  >
                                    {getCoinText(currency)} {value.faceValue}
                                  </ListboxOption>
                                ))}
                            </CustomListBox>
                          )}
                        </div>
                        <div className="button-card">
                          {!item.showEdit && (
                            <button
                              type="button"
                              onClick={() => {
                                changeEdit(item.id, true);
                                setSelectedValue('0');
                              }}
                            >
                              {translations[lang].buttonedit}
                            </button>
                          )}
                          {item.showEdit && (
                            <button
                              disabled={!selectedValue || selectedValue === '0'}
                              onClick={() => {
                                cartItems.filter(
                                  (cartItem) => item.id === cartItem.id,
                                )[0].selectedValue = selectedValue;
                                changeEdit();
                                setCartItems([...cartItems]);
                              }}
                              type="button"
                            >
                              {translations[lang].buttonrefresh}
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={() => {
                              setCartItems(
                                cartItems.filter((cartItem) => {
                                  return cartItem.id !== item.id;
                                }),
                              );
                            }}
                          >
                            {translations[lang].buttonremove}
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="term-review">
                      <label className="container">
                        <input
                          checked={userAgreement}
                          disabled={!userName || !userLastName}
                          onChange={(e) => setUserAgreement(e.target.checked)}
                          type="checkbox"
                        />
                        <span className="checkmark" />
                      </label>
                      <p>
                        {translations[lang].terms.p1}
                        <Link to="/terms" target="_blank">
                          <span> {translations[lang].terms.span1} </span>
                        </Link>
                        {translations[lang].terms.p2}
                        <Link to="/privacy" target="_blank">
                          <span> {translations[lang].terms.span2}</span>
                        </Link>
                      </p>
                    </div>
                    <div className="totalCart">
                      <p>Total:</p>
                      <p>
                        <span>
                          {' '}
                          {getCoinText(currency)} {cartAmount?.toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="catalog">
                <Link to="/">
                  <p className="checkout-text">
                    <ArrowBackIcon /> {translations[lang].buttonbacktocatalog}
                  </p>
                </Link>
                {cartItems.length > 0 && (
                  <button disabled={!userAgreement} onClick={handleComplete} type="button">
                    {translations[lang].buttoncomplete}
                  </button>
                )}
              </div>
            </div>
          )}
        </Content>
      </Main>
    </>
  );
};

export default Checkout;
