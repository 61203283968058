import { TranslationsErrorLanguageTypes } from '../../utils/types';

export const translations: TranslationsErrorLanguageTypes = {
  pt: {
    h1: 'Erro, parece que um de seus itens está fora de estoque',
    p: 'Por favor, tente outra opção voltando ao catálogo ou aguarde até o mesmo esteja disponível novamente',
    buttonbacktocatalog: 'Voltar ao Catálogo',
  },
  es: {
    h1: 'Error, parece que uno de sus artículos está fuera de stock',
    p: 'Intente otra opción volviendo al catálogo o espere hasta que esté disponible nuevamente.',
    buttonbacktocatalog: 'Volver al Catálogo',
  },
  en: {
    h1: 'Error, it looks like one of your items is out of stock',
    p: 'Please return to the catalog for another option or wait until this item is available again.',
    buttonbacktocatalog: 'Return to Catalog',
  },
};
