import { TranslationsProvacyLanguageTypes } from '../../utils/types';

export const translations: TranslationsProvacyLanguageTypes = {
  pt: {
    title: 'AVISO DA POLÍTICA DE PRIVACIDADE DO REWARD LINK®',
    p1: 'O Tango Card respeita a sua privacidade.',
    p2: 'O Tango Card fornece e distribui recompensas aos destinatários em nome dos programas de fidelidade e incentivos dos clientes empresariais (este é o Serviço que oferecemos). Se você recebeu um Prêmio distribuído pelo Tango Card em nome de um Cliente Corporativo do Tango Card, este Aviso se aplica a você como Destinatário. Por favor, leia com atenção.',
    p3: 'Escopo. Este Aviso de Privacidade se aplica apenas às Informações de Identificação Pessoal ("PII") usadas pelo Tango Card para operar o Serviço. PII são dados que podem ser usados, individualmente ou em conjunto com outros dados, para identificar ou entrar em contato com uma pessoa ou família específica. Com relação ao serviço do Tango Card, as PII são limitadas ao nome e e-mail ou endereço postal do Destinatário e ao endereço IP.',
    p4: 'Usar. O Cartão Tango pode obter tais PII de (a) seus Clientes Corporativos para enviar um Prêmio a um Destinatário, ou (b) diretamente de um Destinatário por meio do uso do serviço. Exigimos que um Cliente Corporativo que forneça o Cartão Tango com seu e-mail o faça com o seu consentimento, de acordo com as leis aplicáveis. O Tango Card também recebe e registra informações em nossos logs de servidor. O Tango Card usa essas informações: para atender às suas solicitações de resgate de recompensa, para realizar atendimento ao cliente ou contatá-lo (se necessário) e fornecer relatórios agregados e anônimos para clientes corporativos.',
    p5: 'Se você deseja não receber recompensas por meio do cartão Tango (ou seja, "cancelar" recompensas futuras), você deve transmitir essa solicitação à entidade que está financiando a recompensa (nosso cliente empresarial). Temos uma obrigação contratual com nossos clientes de cumprir as recompensas depois de solicitadas. ',
    p6: 'Usamos PII para fins internos e relacionados ao serviço apenas. O Tango Card não aluga, vende ou compartilha PII a terceiros.',
    p7: 'Divulgação. Podemos divulgar PII a terceiros que nos auxiliam no fornecimento dos Serviços, incluindo, sem limitação: o envio de e-mails, suporte ao cliente, assistência a nós ou ao nosso cliente na proteção contra ou prevenção de fraude real ou potencial, violações de nossos Termos de Serviço do Destinatário, transações não autorizadas, reclamações ou outras responsabilidades. De acordo com qualquer divulgação, esses terceiros estão sob obrigações de confidencialidade para conosco, estão desempenhando uma função de suporte diretamente para nós e não podem usar esses dados, exceto para desempenhar sua função de suporte. Podemos divulgar PII em resposta a uma solicitação, intimação ou ordem judicial de uma agência de aplicação da lei, ou conforme exigido ou permitido por lei ou consistente com os requisitos legais.',
    p8: 'Preservação e exclusão. A Tango Card é uma empresa licenciada de serviços financeiros. Devemos preservar um registro legalmente suficiente de todas as transações de recompensa que realizamos. Devemos manter certas PII para fins de auditoria e regulamentares, incluindo especificamente o endereço de e-mail (e em alguns casos, o nome ou endereço postal) do Destinatário, juntamente com a data, tipo e valor do Prêmio. Esses dados não podem ser excluídos, mas serão mantidos como informações estritamente confidenciais. Na medida em que o Tango Card contenha qualquer outra PII sobre um Destinatário que não seja necessário preservar de acordo com os regulamentos financeiros, o Tango Card cumprirá a lei aplicável em relação a uma solicitação do Destinatário para excluir tais dados.',
    p9: 'Retificação. Os destinatários podem solicitar que as imprecisões relacionadas às suas PII sejam corrigidas. Mediante solicitação, forneceremos ao Destinatário as informações de identificação que coletamos sobre ele (desde que forneçam prova de identidade). Essas informações podem ser solicitadas enviando-nos um e-mail através do link de e-mail fornecido abaixo.',
    p10: 'Transferir. Nós nos reservamos o direito de transferir todas as PII em nosso sistema no caso de o Tango Card ser vendido, fundido ou de outra forma cedido de forma que o controle fique com uma entidade diferente. Garantiremos que qualquer transação inclua um acordo que limitará a coleta, o uso e a divulgação de PII para os fins relacionados à transação.',
    p11: 'Biscoitos. Nosso Site usa "cookies" e tecnologias semelhantes para coletar e armazenar informações que são geradas automaticamente conforme você usa os Serviços, incluindo suas preferências e estatísticas de uso. A maioria dos navegadores permite que você gerencie cookies, incluindo bloqueá-los e excluí-los. Se você bloquear ou excluir nossos cookies, o Serviço pode não funcionar tão bem ou não funcionar para você.',
    p12: 'Segurança. Implementamos procedimentos físicos, eletrônicos e administrativos apropriados em um esforço para proteger e ajudar a prevenir o acesso não autorizado, manter a segurança dos dados e usar corretamente as informações que nos são fornecidas.',
    p13: 'Podemos processar e transferir PII para fora dos Estados Unidos em conexão com o fornecimento do Serviço para nossos clientes corporativos. Se processarmos informações pessoais de um Destinatário localizado fora dos Estados Unidos, exigimos garantias de nosso cliente de que o consentimento adequado foi obtido para esse fim.',
    p14: 'Contato. Se você tiver dúvidas, sugestões ou reclamações sobre esta Política de Privacidade, envie um e-mail para Tango Card em cs@tangocard.com ou 877-55-tango (800-558-2646). A comunicação por escrito deve ser enviada para Tango Card, Inc. Attn: Legal Affairs, 4700 42nd Ave. SW, Suite 430A, Seattle, WA 98116.',
    p15: 'Se nossas práticas de informação mudarem, publicaremos imediatamente quaisquer ajustes a este Aviso neste Site.',
    p16: 'Última atualização: agosto de 2019, atualizado para estar em conformidade com a Lei de Proteção à Privacidade do Consumidor da Califórnia',
  },
  es: {
    title: 'AVISO DE POLÍTICA DE PRIVACIDAD DE REWARD LINK® ',
    p1: 'Tango Card respeta su privacidad. ',
    p2: 'Tango Card proporciona y distribuye recompensas a los destinatarios en nombre de programas e incentivos de fidelización de clientes comerciales (este es el "Servicio" que ofrecemos). Si ha recibido un Premio distribuido por Tango Card en nombre de un Cliente Corporativo de Tango Card, este Aviso se aplica a usted como Destinatario. Por favor, lea atentamente. ',
    p3: 'Alcance. Este Aviso de Privacidad se aplica solo a la Información de Identificación Personal ("PII") utilizada por Tango Card para operar el Servicio. La PII son datos que se pueden usar, individualmente o junto con otros datos, para identificar o contactar a una persona o familia específica. Con respecto al servicio Tango Card, la PII se limita al nombre y la dirección de correo electrónico o dirección postal del Destinatario y la dirección IP. ',
    p4: 'Uso. La Tarjeta Tango puede obtener dicha PII de (A) sus Clientes Corporativos para enviar un Premio a un Destinatario, o (B) directamente de un Destinatario a través del uso del Servicio. Requerimos que un Cliente Corporativo que proporcione la Tarjeta Tango con su correo electrónico lo haga con su consentimiento de acuerdo con las leyes aplicables. Tango Card también recibe y registra información en los registros de nuestro servidor. Tango Card emplea esta información: para cumplir con sus solicitudes de canje de recompensas, para realizar servicio al cliente o para contactarlo (si es necesario) y para proporcionar informes agregados y anónimos a clientes corporativos. ',
    p5: 'Si no desea recibir recompensas a través de la tarjeta Tango (es decir, "cancelar" recompensas futuras), debe transmitir esta solicitud a la entidad que está financiando la recompensa (nuestro cliente comercial). Tenemos la obligación contractual con nuestros clientes de cumplir con las recompensas después de que se soliciten. ',
    p6: 'Usamos PII solo para fines internos y relacionados con el servicio. Tango Card no alquila, vende ni comparte PII a terceros. ',
    p7: 'Divulgación. Podemos divulgar PII a terceros que nos ayudan a proporcionar los Servicios, incluyendo, entre otros: enviar correos electrónicos, atención al cliente, ayudarnos a nosotros o a nuestro cliente a protegernos o prevenir el fraude real o potencial, las violaciones de los Términos de servicio de nuestro Destinatario, las transacciones no autorizadas, las quejas u otras responsabilidades. De acuerdo con cualquier divulgación, estos terceros están bajo obligaciones de confidencialidad con nosotros, están realizando una función de apoyo directamente con nosotros y no pueden utilizar estos datos excepto para realizar su función de soporte. Podemos divulgar PII en respuesta a una solicitud, citación u orden judicial de una agencia de aplicación de la ley, ya sea según lo exija o permita la ley o de acuerdo con los requisitos legales. ',
    p8: 'Preservación y exclusión. Tango Card es una compañía de servicios financieros con licencia. Debemos preservar un registro legalmente suficiente de todas las transacciones de recompensa que llevamos a cabo. Debemos mantener cierta PII para fines de auditoría y regulación, incluyendo específicamente la dirección de correo electrónico (y en algunos casos, el nombre o la dirección postal) del Destinatario, junto con la fecha, el tipo y el valor del Premio. Estos datos no se pueden eliminar, pero se conservarán como información estrictamente confidencial. En la medida en que la tarjeta tango contenga cualquier otra PII en un Destinatario que no sea necesario preservar de acuerdo con las regulaciones financieras, Tango Card cumplirá con la ley aplicable en relación con una solicitud del Destinatario para eliminar dichos datos. ',
    p9: 'Rectificación. Los destinatarios pueden solicitar que se corrijan las inexactitudes relacionadas con su PII. Previa solicitud, proporcionaremos al Destinatario la información de identificación que recopilamos sobre él (siempre que proporcionen una prueba de identidad). Esta información se puede solicitar enviándonos un correo electrónico a través del enlace de correo electrónico que se proporciona a continuación. ',
    p10: 'Transferencia. Nos reservamos el derecho de transferir toda la PII en nuestro sistema en caso de que la Tarjeta Tango se venda, fusione o transfiera de tal manera que el control se deje con una entidad diferente. Nos aseguraremos de que cualquier transacción incluya un acuerdo que limite la recopilación, el uso y la divulgación de PII para fines relacionados con la transacción. ',
    p11: 'Galletas. Nuestro Sitio utiliza "cookies" y tecnologías similares para recopilar y almacenar información que se genera automáticamente a medida que utiliza los Servicios, incluyendo sus preferencias y estadísticas de utilización. La mayoría de los navegadores le permiten administrar las cookies, incluido el bloqueo y la eliminación de estas. Si bloquea o elimina nuestras cookies, es posible que el Servicio no funcione tan bien o que no funcione para usted. ',
    p12: 'Seguridad. Implementamos procedimientos físicos, electrónicos y administrativos apropiados en un esfuerzo por proteger y ayudar a prevenir el acceso no autorizado, mantener la seguridad de los datos y emplear adecuadamente la información que se nos proporciona. ',
    p13: 'Podemos procesar y transferir PII fuera de los Estados Unidos en relación con la prestación del Servicio a nuestros clientes corporativos. Si procesamos información personal de un Destinatario ubicado fuera de los Estados Unidos, requerimos las garantías de nuestro cliente de que se ha obtenido el consentimiento apropiado para ese propósito. ',
    p14: 'Contacto. Si tiene alguna pregunta, sugerencia o queja sobre esta Política de Privacidad, envíe un correo electrónico a Tango Card a cs@tangocard.com o al 877-55-tango (800-558-2646). La comunicación escrita debe enviarse a Tango Card, Inc. A la atención de: Legal Affairs, 4700 42nd Ave. SW, Suite 430A, Seattle, WA 98116. ',
    p15: 'Si nuestras prácticas de información cambian, publicaremos inmediatamente cualquier ajuste a este Aviso en este Sitio. ',
    p16: 'Última actualización: agosto de 2019, actualizado para cumplir con la Ley de Protección de la Privacidad del Consumidor de California. ',
  },
};
