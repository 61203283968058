import { createGlobalStyle } from 'styled-components';
import { getPadding } from './marginsPaddings';
import { convertPixelToRem } from './sizing';

export const GlobalStyle = createGlobalStyle`

  [data-reach-accordion-panel][data-state="open"] {
    font-size: ${convertPixelToRem(12)};
    text-align: center;
    color: ${({ theme }) => theme.colors.colorText};
    animation: slide-down 0.4s ease;
    
    div {
      border: solid 1px;
      border-color: ${({ theme }) => theme.colors.cardButton};
      width: 65px;
      margin-top: 8px;
      margin-right: 250px;
    }

    @media(max-width: 500px) {
      div {
     margin-left: 10px;
    }
  }
  }

  [data-reach-accordion-button]:last-child {
    margin-left: 5px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    outline: none;
  }

  @media(max-width: 960px) {
    html {
      font-size: 93.75%; // 15px
    }
  }

  @media(max-width: 600px) {
    html {
      font-size: 87.5%; // 14px
    }
  }


  body {
    background-color: ${({ theme }) => theme.colors.mainGradient};
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: thin;
    scrollbar-color: #4C76F3;
  }

  body::-webkit-scrollbar {
    width: 0.9375rem;
  }

  body::-webkit-scrollbar-track {
    background: ${({ theme: th }) => th.colors.background};
  }

  body::-webkit-scrollbar-thumb {
    background-color: #4C76F3;
    border-radius: 6px;
    border: 5px solid ${({ theme: th }) => th.colors.background};
  }

  button {
    cursor: pointer;
    font: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down[data-reach-menu-list],
.slide-down[data-reach-menu-items] {
  border-radius: 5px;
  animation: slide-down 0.2s ease;
}

[data-reach-dialog-content] {
    width: 70vw;
    margin: 10vh auto;
    background: white;
    padding: 1rem;
    outline: none;
    background: #EFEFEF;
    margin-top: 115px;

    @media screen and (max-width: 499px) {
      width: 90vw;
      margin-top: 115px;
      ${getPadding(15, 0, 10, 0)};
    }
  }

  [data-reach-menu-popover] {
    z-index: 5;
    margin-left: 90px;
    animation: slide-down 0.3s ease;

    @media screen and (max-width: 499px) {
      margin-left: 0;
    }

    h1 {
      margin-bottom: 10px;
    }

    button {
      text-transform: uppercase;
      border: none;
      background: ${({ theme }) => theme.colors.colorTextButtonCheckout};
      border-radius: 5px;
      white-space: nowrap;
      width: 250px;
      color: ${({ theme }) => theme.colors.primary};
      height: 37px;
      margin-bottom: 10px;

      
    }
      button:disabled,
      button[disabled],
      button[disabled].button-cart:hover {
        background-color: #cccccc;
        color: #666666;
      }

    [data-reach-menu-item] {
      border-bottom: 1px solid;
      border-color: ${({ theme }) => theme.colors.cardButton};
      display: flex;
      justify-content: space-between;

      .total-items {
        font-size: ${convertPixelToRem(14)};
        color: ${({ theme }) => theme.colors.colorText};
        font-weight: 600;
      }

      p {
        font-size: ${convertPixelToRem(14)};
        color: ${({ theme }) => theme.colors.colorText};
        font-weight: 400;
      }

      .checkout-text {
        font-size: ${convertPixelToRem(18)};
        color: ${({ theme }) => theme.colors.colorText};
        margin-bottom: 10px;
        margin-top: 10px;

        span {
          font-weight: 600;
          font-size: ${convertPixelToRem(16)};
        }
      }

      .checkout-cart {
        font-size: ${convertPixelToRem(13)};
        color: ${({ theme }) => theme.colors.colorText};
        margin-top: 10px;
        word-wrap: break-word;
      }

      .edit-remove {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
      }
    }

    [data-reach-menu-item]:last-child {
      border: none;
      display: block;
    } 

    [data-reach-menu-item][data-selected] {
      background: none;
      color: black;
    }

    [data-reach-menu-list], [data-reach-menu-items] {
      display: block;
      white-space: nowrap;
      border: none;
      background: hsla(0, 100%, 100%, 0.99);
      outline: none;
      padding: 1rem 0;
      font-size: 85%;
      box-shadow: 0 0 40px -10px rgb(0 0 0 / 25%); 
      border-radius: 7px;
    }
  }
`;
