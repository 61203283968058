import React, { ReactElement } from 'react';
import { Container } from '../../components/Header/styles';
import { Content, Main } from './styles';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { ReactComponent as LogoIconQueOpinas } from '../../assets/queopinas_logo.svg';
import lang from '../../services/lang';
import { translations } from './translations';

const Privacy: React.FC = () => {
  const getLogoPerLanguage = (): ReactElement => {
    if (lang === 'pt') {
      return <LogoIcon />;
    }
    if (lang === 'es') {
      return <LogoIconQueOpinas width="168" height="27" />;
    }

    return <LogoIconQueOpinas width="168" height="27" />;
  };

  return (
    <>
      <Container>
        <div className="content">
          <div className="logo">
            <button type="button">{getLogoPerLanguage()}</button>
          </div>
        </div>
      </Container>
      <Main>
        <Content>
          <div className="checkout">
            <h1>{translations[lang].title}</h1>
            <p className="data-text">{translations[lang].p1}</p>
            <p className="data-text">{translations[lang].p2}</p>
            <p className="data-text">{translations[lang].p3}</p>
            <p className="data-text">{translations[lang].p4}</p>
            <p className="data-text">{translations[lang].p5}</p>
            <p className="data-text">{translations[lang].p6}</p>
            <p className="data-text">{translations[lang].p7}</p>
            <p className="data-text">{translations[lang].p8}</p>
            <p className="data-text">{translations[lang].p9}</p>
            <p className="data-text">{translations[lang].p10}</p>
            <p className="data-text">{translations[lang].p11}</p>
            <p className="data-text">{translations[lang].p12}</p>
            <p className="data-text">{translations[lang].p13}</p>
            <p className="data-text">{translations[lang].p14}</p>
            <p className="data-text">{translations[lang].p15}</p>
            <p className="data-text">{translations[lang].p16}</p>
          </div>
        </Content>
      </Main>
    </>
  );
};

export default Privacy;
