import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppRoutes from './routes';
import ErrorBoundary from './components/ErrorBoundary';

import AppProvider from './hooks';

import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';
import ScrollToTop from './components/ScrollTop';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ErrorBoundary>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
