import { css, FlattenSimpleInterpolation } from 'styled-components';

import { APP_HEADER_PX_SIZE, PIXEL_TO_REM } from '../utils/appConstants';

export type PixelOptions = number | 'auto';

export function convertPixelToRem(px: PixelOptions): string {
  if (px === 'auto') return 'auto';

  return `${px / PIXEL_TO_REM}rem`;
}

export const headerHeight = css`
  height: ${convertPixelToRem(APP_HEADER_PX_SIZE)};
`;

export const baseFontSizing = css`
  font-size: ${convertPixelToRem(14)};
  line-height: ${convertPixelToRem(21)};
`;

export const tableFontSizing = css`
  font-size: ${convertPixelToRem(13)};
  line-height: ${convertPixelToRem(19.5)};
`;

export const modalTitleFontSizing = css`
  font-size: ${convertPixelToRem(22)};
  line-height: ${convertPixelToRem(25)};
`;

export const modalDescFontSizing = css`
  font-size: ${convertPixelToRem(17)};
  line-height: ${convertPixelToRem(25)};
`;

export const labelWithTextSizing = css`
  font-size: ${convertPixelToRem(12)};
  line-height: ${convertPixelToRem(18)};
`;

export const getBoxWidthAndHeight = (boxSize: number): FlattenSimpleInterpolation => css`
  height: ${boxSize}px;
  width: ${boxSize}px;
`;

export const fixedAtTop = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
