import styled from 'styled-components';
import { getMargin, getPadding } from '../../styles/marginsPaddings';
import { convertPixelToRem } from '../../styles/sizing';

export const Main = styled.div`
  background: ${({ theme }) => theme.colors.mainGradient};
  ${getMargin(4)};
  min-height: 100vh;
`;

export const Content = styled.div`
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  padding: 15px;

  div {
    width: 100%;

    button {
      margin-top: ${convertPixelToRem(40)};
      border: 2px solid #4d77f4;
      box-sizing: border-box;
      border-radius: ${convertPixelToRem(27)};
      border-radius: ${convertPixelToRem(27)};
      font-style: normal;
      color: #ffffff;
      background: #4d77f4;
      padding-top: ${convertPixelToRem(5)};
      padding-bottom: ${convertPixelToRem(5)};
      padding-left: ${convertPixelToRem(30)};
      padding-right: ${convertPixelToRem(30)};
      font-weight: bold;
      font-size: ${convertPixelToRem(16)};
      line-height: ${convertPixelToRem(19)};
      filter: drop-shadow(0px 10px 20px rgba(23, 72, 147, 0.2));
    }

    button:hover {
      margin-top: ${convertPixelToRem(40)};
      border: 2px solid #4d77f4;
      box-sizing: border-box;
      border-radius: ${convertPixelToRem(27)};
      border-radius: ${convertPixelToRem(27)};
      font-style: normal;
      color: #4d77f4;
      background: none;
      padding-top: ${convertPixelToRem(5)};
      padding-bottom: ${convertPixelToRem(5)};
      padding-left: ${convertPixelToRem(30)};
      padding-right: ${convertPixelToRem(30)};
      font-weight: bold;
      font-size: ${convertPixelToRem(16)};
      line-height: ${convertPixelToRem(19)};
      filter: drop-shadow(0px 10px 20px rgba(23, 72, 147, 0.2));
    }
  }

  .checkout {
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 4px 4px rgba(61, 75, 127, 0.25);
    border-radius: 5px;
    padding: 45px;
    margin-bottom: 40px;
    margin-top: 12%;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-top: 80px;
    }

    .data-text {
      font-size: ${convertPixelToRem(14)};
      color: ${({ theme }) => theme.colors.colorText};
      margin-top: 25px;
      margin-bottom: 25px;
      margin-left: 25px;
      flex-wrap: wrap;
    }

    .request-text {
      font-size: ${convertPixelToRem(20)};
      color: #444b62;
      font-weight: 300;
      margin-top: 25px;
      margin-left: 25px;
      text-align: center;
    }

    h1 {
      justify-content: center;
      text-align: center;
      color: #444b62;
    }

    .main {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      div {
        justify-content: center;
        text-align: center;
      }
    }

    .review-card {
      margin-top: 50px;
      margin-bottom: 50px;

      .text-review {
        display: flex;
        justify-content: space-between;
      }

      .card {
        width: 100%;
        max-width: 500px;
        background: ${({ theme }) => theme.colors.background};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        align-items: center;

        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
        }

        .request-text-card {
          font-size: ${convertPixelToRem(20)};
          color: ${({ theme }) => theme.colors.colorText};
          font-weight: 300;
        }

        .request-text-card-title {
          font-size: ${convertPixelToRem(20)};
          color: ${({ theme }) => theme.colors.colorText};
          font-weight: 600;
        }

        .request-text-premium {
          font-size: ${convertPixelToRem(10)};
          color: ${({ theme }) => theme.colors.colorText};
          font-weight: 300;
        }

        img {
          padding: 21px;
        }

        .button-card {
          display: block;
          text-align: center;
        }

        button {
          width: 153px;
          ${getPadding(6)};
          margin-bottom: 10px;
          background: none;
          border: solid 1px;
        }
      }
    }
  }
`;
