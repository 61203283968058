import React, { useEffect } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { Content, Main } from './styles';
import { useCart } from '../../hooks/Cart';
import lang from '../../services/lang';
import { translations } from './translations';

const Requests: React.FC = () => {
  const { outStock } = useCart();

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Content>
          <div className="checkout">
            <Link to="/">
              <p className="checkout-text">
                <ArrowBackIcon /> {translations[lang].buttonbacktocatalog}
              </p>
            </Link>
            <div className="main">
              <ErrorOutlineIcon style={{ fontSize: 80 }} />
            </div>
            <h1>{translations[lang].h1}</h1>
            {outStock.map((item) => (
              <div className="card">
                <div key={`checkout-item-${item.id}`} className="card">
                  <img src={item.imageUrls['200w-326ppi']} alt="" />
                  <div>
                    <p className="request-text-card-title">{item.brandName}</p>
                    {!item.showEdit && (
                      <p className="request-text-card">
                        {item.items[0].currencyCode} {item.selectedValue}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <p className="request-text">{translations[lang].p}</p>
          </div>
        </Content>
      </Main>
    </>
  );
};

export default Requests;
