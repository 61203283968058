import { TranslationsRequestLanguageTypes } from '../../utils/types';

export const translations: TranslationsRequestLanguageTypes = {
  pt: {
    title: 'Nós recebemos o seu pedido!',
    text: 'Estamos processando seu pedido. Você será notificado por e-mail.',
    awardtext:
      'Os prêmios são processados instantaneamente mas pode levar até um dia para serem entregues por e-mail.',
    button: 'Voltar para Lojas',
  },
  es: {
    title: '¡Hemos recibido su pedido!',
    text: 'Estamos procesando su pedido. Se le notificará por correo electrónico.',
    awardtext:
      'Las recompensas se procesan al instante, pero pueden tardar hasta un día en enviarse por correo electrónico.',
    button: 'Volver a las Tiendas',
  },
  en: {
    title: 'We have received your order!',
    text: 'We are processing your order. You will be notified via email once it is ready.',
    awardtext:
      'Rewards are processed instantly, but it could take up to one (1) business day to be delivered via email.',
    button: 'Go to Store',
  },
};
