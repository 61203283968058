export const APP_RESPONSIVE_SIZE = 960;

export const APP_RESPONSIVE_SIZE_PX = '960px';

export const APP_HEADER_PX_SIZE = 76;

export const INDEX_TO_HUMAN_ORDER_OFFSET = 1;

export const ARRAY_START = 0;

export const PIXEL_TO_REM = 16;

export const TOKEN_PARAM = 'token';

export const TOKEN_LS_KEY = 'user_token';

export const EMAIL_PARAM = 'email';

export const EMAIL_LS_KEY = 'user_email';
