import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  useEffect,
  ReactElement,
} from 'react';
import api from '../services/api';
import { EMAIL_LS_KEY, TOKEN_LS_KEY, TOKEN_PARAM } from '../utils/appConstants';
import { useQueryParams } from './queryParams';
import { Main, Currency } from '../pages/Home/styles';
import { ReactComponent as LogoIconQueOpinas } from '../assets/queopinas_logo.svg';
import { ReactComponent as LogoIcon } from '../assets/logo.svg';
import lang from '../services/lang';
import { useLoading } from './Loading';

type tItem = {
  countries: Array<string>;
  utid: string;
  faceValue: number;
  redemptionInstructions: string;
  rewardName: string;
  currencyCode: string;
};

export interface tCartItem {
  id: number;
  brandName: string;
  brandKey: string;
  shortDescription: string;
  description: string;
  disclaimer: string;
  terms: string;
  imageUrls: { '300w-326ppi': string; '200w-326ppi': string };
  items: Array<tItem>;
  selectedValue: string | number;
  showEdit?: boolean | undefined;
}

interface UseCart {
  loading: boolean;
  cartAmount: number;
  amount: number;
  cartItems: Array<tCartItem>;
  email: string;
  user: User;
  outStock: Array<tCartItem>;
  currency: string;
  check: any;
  setCurrency: Dispatch<string>;
  setEmail: Dispatch<string>;
  setLoading: Dispatch<boolean>;
  setCartAmount: Dispatch<number>;
  setCartItems: Dispatch<Array<tCartItem>>;
  setStock: Dispatch<Array<tCartItem>>;
  setCheck: Dispatch<any>;
}

function calcCartAmount(cartItems: tCartItem[], amount: number): React.SetStateAction<number> {
  return cartItems.reduce(
    (acumul, currentItem) => Number(acumul) - Number(currentItem.selectedValue),
    amount,
  );
}

interface User {
  panelist: {
    panelistId: string;
    amount: number;
    currency: string;
    email: string;
    firstName: string;
    lastName: string;
  };
}

interface CoinsName {
  [key: string]: string;
}

export const getCoinText = (coin: string): string => {
  const namesCoin: CoinsName = {
    BRL: 'R$ ',
    MXN: 'MXN ',
    ARS: 'ARS ',
    PEN: 'PEN ',
    COP: 'COP ',
  };

  return namesCoin[coin as keyof CoinsName];
};

export const getValuesPerCoin = (coin: string): string => {
  const values: CoinsName = {
    MXN: 'MXN 50,00 a MXN 1000,00',
    ARS: 'ARS 50,00 a ARS 2.600,00',
    BRL: 'R$ 20,00 a R$ 300,00',
    PEN: 'PEN 10,00 a PEN 200,00',
    COP: 'COP 8700,00 a COP 200.000,00',
  };

  return values[coin as keyof CoinsName];
};

export const getMaxValuesPerCoin = (coin: string): string => {
  const values: CoinsName = {
    MXN: '400,00',
    ARS: '2.600,00',
    BRL: '100,00',
    PEN: '74,00',
    COP: '81.000,00',
  };

  return values[coin as keyof CoinsName];
};

const CartContext = createContext<UseCart>({} as UseCart);

export const CartProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [cartAmount, setCartAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState('');
  const [cartItems, setCartItems] = useState<Array<tCartItem>>([]);
  const [user, setUser] = useState<User>({} as User);
  const [currency, setCurrency] = useState('');
  const [outStock, setStock] = useState<Array<tCartItem>>([]);
  const { turnLoadingOff } = useLoading();

  const token = sessionStorage.getItem(TOKEN_LS_KEY);
  const queryParams = useQueryParams();
  const [check, setCheck] = useState(<></>);

  const getLogoPerLanguage = (): ReactElement => {
    if (lang === 'pt') {
      return <LogoIcon />;
    }
    if (lang === 'es') {
      return <LogoIconQueOpinas width="168" height="27" />;
    }

    return <LogoIconQueOpinas width="168" height="27" />;
  };

  useEffect(() => {
    const [queryToken] = queryParams[TOKEN_PARAM] || [];
    const userToken = queryToken || token;

    async function getUser(): Promise<void> {
      try {
        const { data: userData } = await api.post('/validate', { token: userToken });
        setUser(userData);
        setAmount(userData.panelist.amount);
        setCurrency(userData.panelist.currency);
        sessionStorage.setItem(TOKEN_LS_KEY, userToken as string);
        sessionStorage.setItem(EMAIL_LS_KEY, userData.panelist.email);
      } catch (err) {
        turnLoadingOff();
        setUser({} as User);
        setCheck(
          <>
            <Main>
              <Currency>
                <div>{getLogoPerLanguage()}</div>
                <h1>Oi! Para acessar essa página você deve clicar no link enviado por e-mail.</h1>
                <h1>
                  ¡Hola! Para acceder a esta página debe hacer clic en el link enviado por correo
                  electrónico.
                </h1>
                <h1>Hi! To access this page you must click on the link sent by email.</h1>
              </Currency>
            </Main>
          </>,
        );
      }
    }

    getUser();
  }, []) // eslint-disable-line

  useEffect(() => {
    setCartAmount(
      cartItems.reduce(
        (acumul, currentItem) => Number(acumul) + Number(currentItem.selectedValue),
        0,
      ),
    );
    setAmount(calcCartAmount(cartItems, user.panelist ? user.panelist.amount : 0));
  }, [cartItems, user.panelist]);

  return (
    <CartContext.Provider
      value={{
        loading,
        cartAmount,
        cartItems,
        amount,
        email,
        user,
        outStock,
        currency,
        check,
        setEmail,
        setLoading,
        setCartAmount,
        setCartItems,
        setStock,
        setCurrency,
        setCheck,
      }}
    >
      <>{children}</>
    </CartContext.Provider>
  );
};

export function useCart(): UseCart {
  const context = useContext(CartContext);

  return context;
}
